import React from "react";
import BACK from "../../assets/services-bottom-back8.jpg";
import HorizontalCard from "../../components/HorizontalCard.js";

const AboutUs = ({ content }) => {
  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .about-us-back {
          background-image: url(${BACK});
          background-size: 300%;
          background-position: center;
          background-repeat: no-repeat;
          color: #fff;
          overflow-x: hidden;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          position: relative;
        }

        .about-us-content {
          margin-top: 10vh;
          padding: 2rem;
          border-radius: 15px;
        }

        .about-us-heading {
          font-size: 3vw;
          font-weight: bold;
          text-align: center;
          color: #68755c;
        }

        .about-us-line {
          width: 50%;
          margin: 1rem auto;
          border: 1px solid #fff;
        }

        .about-us-para {
          font-size: 1.1vw;
          font-weight: 500;
          color: black;
          text-align: center;
        }

        .spinner-border {
          color: #fff;
        }

        @media screen and (max-width: 768px) {
          .about-us-heading {
            font-size: 6vw;
          }

          .about-us-para {
            font-size: 3vw;
          }
        }
      `}</style>
      <div className="about-us-back">
        <div className="about-us-content container">
          <h1 id="about-us-heading" className="about-us-heading">
            About Us
          </h1>
          <hr className="about-us-line" />
          {content[0]?.description && (
            <p id="about-us-description" className="about-us-para">
              {content[0]?.description}
            </p>
          )}
        </div>
        <div className="about-bottom-section container">
          {content?.slice(1, content?.length).map((aboutUsItem, index) => (
            <HorizontalCard
              key={aboutUsItem?.id}
              image={aboutUsItem?.image}
              content={{
                title: aboutUsItem?.name,
                body: aboutUsItem?.description,
              }}
              imageOnRight={index % 2 == 0}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
