import React, { useState } from "react";
import "./TestimonialCarousel.scss";
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import { FaVideo, FaImage } from "react-icons/fa";
import FileViewModal from "../../Modal/FileViewModal";

const TestimonialCarousel = ({ items }) => {
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState();
  const [viewButtonModalShow, setViewButtonModalShow] = useState(false);

  const settings = {
    dots: items.length > 1,
    infinite: items.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 45000,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  const handleViewButton = () => {
    setViewButtonModalShow((prevState) => !prevState);
  };

  return (
    <>
      <Slider {...settings} className="carousel-slider-class">
        {items.map((item, index) => (
          <div className="d-flex justify-content-center" key={index}>
            <div className="content">
              <div className="testimonial">
                <blockquote>
                  {item?.feedback}
                  <div className="d-flex justify-content-center align-items-center pt-3">
                    <div className="file-show">
                      {item?.file && /\.(mp4|avi|mov)$/i.test(item.file) ? (
                        <FaVideo
                          className="file-icon"
                          onClick={() => {
                            setFile(item.file);
                            setFileType("video");
                            handleViewButton();
                          }}
                        />
                      ) : item?.file ? (
                        <FaImage
                          className="file-icon"
                          onClick={() => {
                            setFile(item.file);
                            setFileType("image");
                            handleViewButton();
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </blockquote>
                <div className="name-mark" />
                <p>
                  {item.name} &mdash; {item.location}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <FileViewModal
        show={viewButtonModalShow}
        onHide={() => setViewButtonModalShow(!viewButtonModalShow)}
        file={file}
        fileType={fileType}
      />
    </>
  );
};

export default TestimonialCarousel;
