import React, { useMemo, useState } from 'react';
import { useTable, useExpanded } from 'react-table';
import { Button } from 'react-bootstrap';
import { useStartExpanded } from './useStartExpanded';

const HierarchicalTable = ({ columns,selectedData }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: selectedData,
      initialState: { startExpanded: true },
    },
    useExpanded,
    useStartExpanded
  );

  return (
    <table {...getTableProps()} className="table table-striped table-bordered">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={row.original.isService ? 'table-primary' : row.original.isCategory ? 'table-info' : ''}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default HierarchicalTable;
