import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const DealsCard = ({
  id,
  name,
  services,
  price,
  discounted_price,
  onSelectDeal,
  isSelected,
}) => {
  return (
    <>
      <style jsx="true">{`
        .deals-card-background.selected {
          border: 3px solid green;
          position: relative;
        }
        .learn-more-btn {
          font-size: 1vw;
          font-weight: 500;
          color: #ffffff;
          background: linear-gradient(to top right, #68755c, #869672);
          border: none;
          border-radius: 3px;
          text-decoration: none;
          text-transform: uppercase;
          padding: 1% 5%;

          &:hover {
            color: #121212;
            transition: 0.3s all ease-in-out;
          }
        }
        .selected-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          color: green;
          cursor: pointer;
          background: white;
          padding: 5px;
          border-radius: 50%;
          z-index: 999;
        }
        .list-icon-2 {
          width: 25%;
          display: flex;
          justify-content: space-around;
          cursor: pointer;
          opacity: 0;
          transform: rotateY(180deg);
          transition: all 0.3s ease-in-out;
          position: absolute;
          top: 30%;
          bottom: 60%;
        }
        .deals-card-background.selected.no-hover {
          &:hover {
            .card-image-class,
            .card-body-class {
              opacity: 1;
              transition: none;
            }
            .learn-more-btn {
              display: none;
            }
            .quantity-selector {
              opacity: 0;
              transition: none;

              .btn-booking {
                opacity: 0;
                transition: none;
              }
            }
            .list-icon-2 {
              opacity: 0;
              transition: none;
            }
            transform: none;
          }
        }
        .deals-card-background {
          width: 325px;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: white;
          border-radius: 15px;
          border: 5px solid #f3c2af;
          margin: 15px 15px 30px 15px;
          overflow: hidden;
          position: relative;
          transition: all 0.3s ease-in-out;

          .card-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .card-body-class {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 15px 15px 15px;

            .card-title-class {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #f3c2af;
              border-radius: 3px;
              padding: 3px 20px 3px 20px;

              .card-title {
                color: #121212;
                font-size: 1.3vw;
                text-transform: uppercase;
              }
            }

            .card-content-class {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: center;
              padding: 10px 10px 10px 10px;

              .service-class {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .service-name {
                  color: #121212;
                  font-size: 0.9vw;
                  margin: 5px 0px 5px 0px;
                  text-transform: uppercase;
                  text-align: left;
                }
              }

              .price-class {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;

                .service-price {
                  color: #121212;
                  font-size: 0.9vw;
                  margin: 5px 0px 5px 0px;
                  text-transform: uppercase;
                }
              }
            }

            .card-button-class {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .btn-booking {
                width: 50%;
                font-size: 0.8vw;
                border: none;
                border-radius: 3px;
                color: #121212;
                background-color: #f3c2af;
                cursor: pointer;
                transition: all 0.2s ease;
                margin: 0;

                &:hover {
                  background-color: #dda38c;
                  transition: 0.2s all ease;
                }
              }
            }
          }

          &:hover {
            .card-image-class {
              opacity: 0;
              transition: all 0.5s ease-in-out;
            }

            .card-body-class {
              opacity: 0;
              transition: all 0.5s ease-in-out;
            }

            .quantity-selector {
              opacity: 1;
              transition: all 2s ease-in-out;

              .btn-booking {
                opacity: 1;
                transition: all 2s ease-in-out;

                &:hover {
                  color: #121212;
                  transition: 0.2s all ease-in-out;
                }
              }
            }

            .list-icon-2 {
              opacity: 1;
              transition: all 1.2s ease-in-out;
            }

            transition: all 1s ease-in-out;
            transform: rotateY(180deg);
            z-index: 1;
          }
        }

        @media screen and (max-width: 1430px) {
          .deals-card-background {
            width: 300px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            border: 5px solid #f3c2af;
            margin: 10px 10px 30px 10px;

            .card-body-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 15px 15px 15px;

              .card-title-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f3c2af;
                padding: 3px 20px 3px 20px;

                .card-title {
                  color: #121212;
                  font-size: 1.5vw;
                  text-transform: uppercase;
                }
              }

              .card-content-class {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;

                .service-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .service-name {
                    color: #121212;
                    font-size: 1vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }

                .price-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;

                  .service-price {
                    color: #121212;
                    font-size: 1vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }
              }

              .card-button-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-booking {
                  width: 50%;
                  font-size: 0.9vw;
                  border: none;
                  border-radius: 3px;
                  color: #121212;
                  background-color: #f3c2af;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:hover {
                    background-color: #dda38c;
                    transition: 0.2s all ease;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 1225px) {
          .deals-card-background {
            width: 250px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            border: 5px solid #f3c2af;
            margin: 10px 12px 30px 12px;

            .card-body-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 15px 15px 15px;

              .card-title-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f3c2af;
                padding: 3px 20px 3px 20px;

                .card-title {
                  color: #121212;
                  font-size: 1.8vw;
                  text-transform: uppercase;
                }
              }

              .card-content-class {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;

                .service-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .service-name {
                    color: #121212;
                    font-size: 1.3vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }

                .price-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;

                  .service-price {
                    color: #121212;
                    font-size: 1.3vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }
              }

              .card-button-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-booking {
                  width: 50%;
                  font-size: 1.1vw;
                  border: none;
                  border-radius: 3px;
                  color: #121212;
                  background-color: #f3c2af;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:hover {
                    background-color: #dda38c;
                    transition: 0.2s all ease;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 991px) {
          .deals-card-background {
            width: 275px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            border: 5px solid #f3c2af;
            margin: 10px 5px 30px 5px;

            .card-body-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 15px 15px 15px;

              .card-title-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f3c2af;
                padding: 3px 20px 3px 20px;

                .card-title {
                  color: #121212;
                  font-size: 2vw;
                  text-transform: uppercase;
                }
              }

              .card-content-class {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;

                .service-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .service-name {
                    color: #121212;
                    font-size: 1.5vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }

                .price-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;

                  .service-price {
                    color: #121212;
                    font-size: 1.5vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }
              }

              .card-button-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-booking {
                  width: 50%;
                  font-size: 1.3vw;
                  border: none;
                  border-radius: 3px;
                  color: #121212;
                  background-color: #f3c2af;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:hover {
                    background-color: #dda38c;
                    transition: 0.2s all ease;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 768px) {
          .deals-card-background {
            width: 275px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            border: 5px solid #f3c2af;
            margin: 10px 10px 30px 10px;

            .card-body-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 15px 15px 15px;

              .card-title-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f3c2af;
                padding: 3px 20px 3px 20px;

                .card-title {
                  color: #121212;
                  font-size: 3vw;
                  text-transform: uppercase;
                }
              }

              .card-content-class {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;

                .service-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .service-name {
                    color: #121212;
                    font-size: 2.2vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }

                .price-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;

                  .service-price {
                    color: #121212;
                    font-size: 2.2vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }
              }

              .card-button-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-booking {
                  width: 50%;
                  font-size: 2vw;
                  border: none;
                  border-radius: 3px;
                  color: #121212;
                  background-color: #f3c2af;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:hover {
                    background-color: #dda38c;
                    transition: 0.2s all ease;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 480px) {
          .deals-card-background {
            width: 275px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            border: 5px solid #f3c2af;
            margin: 10px 10px 30px 10px;

            .card-body-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 15px 15px 15px;

              .card-title-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f3c2af;
                padding: 3px 20px 3px 20px;

                .card-title {
                  color: #121212;
                  font-size: 4vw;
                  text-transform: uppercase;
                }
              }

              .card-content-class {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 10px 10px 10px;

                .service-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .service-name {
                    color: #121212;
                    font-size: 3vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }

                .price-class {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;

                  .service-price {
                    color: #121212;
                    font-size: 3vw;
                    margin: 5px 0px 5px 0px;
                    text-transform: uppercase;
                  }
                }
              }

              .card-button-class {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-booking {
                  width: 50%;
                  font-size: 2.5vw;
                  border: none;
                  border-radius: 3px;
                  color: #121212;
                  background-color: #f3c2af;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:hover {
                    background-color: #dda38c;
                    transition: 0.2s all ease;
                  }
                }
              }
            }
          }
        }
      `}</style>

      <div
        className={`deals-card-background ${
          isSelected ? "selected no-hover" : ""
        }`}
      >
        {isSelected && (
          <div onClick={() => onSelectDeal(id)}>
            <IoMdCheckmarkCircleOutline className="selected-icon" size={34} />
          </div>
        )}
        <div className="card-content">
          <div className="card-body-class">
            <div className="card-title-class">
              <h5 className="card-title">{name}</h5>
            </div>
            <div className="my-2">
              <span className="fw-bold me-2">Price:</span>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "red",
                  marginRight: "10px",
                }}
              >
                {price.toFixed(2)}
              </span>
              <span>{discounted_price.toFixed(2)}</span>
            </div>
            <div className="card-content-class">
              <div className="service-class">
                {services.map((item, index) => (
                  <h6 className="service-name">
                    {item?.split("(")[0]}
                    {item?.split("(")[1] && (
                      <strong style={{ color: "#9A154A" }}>
                        <br />
                        {"(" + item?.split("(")[1]}
                      </strong>
                    )}
                  </h6>
                ))}
              </div>
              {/* <div className="price-class col-4">
                {prices.map((item, index) => (
                  <h6 className="service-price">{item}</h6>
                ))}
              </div> */}
            </div>
            <div className="list-icon-2 ">
              <div className="card-button-class">
                <button
                  className="learn-more-btn"
                  onClick={() => onSelectDeal(id)}
                >
                  {isSelected ? "Deselect" : "Select"}{" "}
                  <HiArrowLongRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-content">
          <div className="list-icon-2 w-100 h-100 top-0 bottom-0">
            <div className="d-flex flex-column align-items-center justify-content-center w-100">
              <button
                className="learn-more-btn"
                onClick={() => onSelectDeal(id)}
              >
                {isSelected ? "Deselect" : "Select"}{" "}
                <HiArrowLongRight size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsCard;
