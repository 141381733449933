import * as yup from "yup";

const YupSchema = (function () {
  const contact = yup.object({
    name: yup
      .string()
      .max(30, "Must be 30 characters or less")
      .required("Name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: yup
      .string()
      .required("Please provide your phone number")
      .matches(/^\d{11}$/, "Invalid phone number"),
    message: yup
      .string()
      .max(200, "Message must be at most 200 characters")
      .required("Message is required"),
  });
  const hiring = yup.object({
    name: yup
      .string()
      .max(30, "Must be 30 characters or less")
      .required("Name is required"),
    email: yup.string().email("Invalid email address").optional().nullable(),
    message: yup.string().max(200, "Message must be at most 200 characters"),
    position_applying_for: yup.string().required("Designation is required"),
    phone_number: yup
      .string()
      .required("Please provide your phone number")
      .matches(/^\d{11}$/, "Invalid phone number"),
    upload_cv: yup
      .mixed()
      .test("fileType", "File must be a PDF", function (value) {
        if (value?.length === 0) return true;

        const fileType = value[0] && value[0].type;
        return fileType === "application/pdf";
      })
      .test(
        "fileSize",
        "File size must be less than or equal to 5MB",
        function (value) {
          if (value?.length === 0) return true;
          const fileSize = value[0] && value[0].size;
          const maxSize = 5 * 1024 * 1024;
          return fileSize <= maxSize;
        }
      ),
  });
  const feedback = yup.object({
    name: yup
      .string()
      .max(30, "Must be 30 characters or less")
      .required("Name is required"),
    email: yup.string().email("Invalid email address"),
    location: yup
      .string()
      .max(30, "Must be 30 characters or less")
      .required("Location is required"),
    message: yup.string().required("Message is required"),
    phone_number: yup
      .string()
      .required("Please provide your phone number")
      .matches(/^\d{11}$/, "Invalid phone number"),
    file: yup
      .mixed()
      .test("fileType", "File must be an image or a video", function (value) {
        if (value.length === 0) return true;
        const fileType = value[0]?.type;
        if (!fileType) return false;
        return /^image\/(png|jpe?g|gif)$|^video\/(mp4|avi|mov)$/.test(fileType);
      })
      .test(
        "fileSize",
        "File size exceeds the limit of 10MB for video & 5MB for image",
        function (value) {
          if (value.length === 0) return true;

          const file = value[0];
          const fileSize = file.size;
          const fileType = file.type;

          if (fileType.startsWith("image")) {
            const maxSize = 5 * 1024 * 1024;
            return fileSize <= maxSize;
          } else if (fileType.startsWith("video")) {
            const maxSize = 10 * 1024 * 1024;
            return fileSize <= maxSize;
          } else {
            return false;
          }
        }
      ),
  });

  return {
    contact,
    hiring,
    feedback,
  };
})();

export default YupSchema;
