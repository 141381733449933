import React from "react";

/**
 *
 * @param {image} url
 * @param {image} image
 * @param {content} object - { title: '', body: ''}
 * @param {imageOnRight} boolean - default is false
 */

const HorizontalCard = ({ image, content, imageOnRight }) => {
  const body = content?.body?.split("•");
  return (
    <>
      <div
        className="horizontal-card"
        style={{ flexDirection: imageOnRight ? "row-reverse" : "row" }}
      >
        <div className="horizontal-card-item">
          <img
            src={image}
            alt="Description"
            className="image"
            loading="lazy"
            id="horizontal-card-img"
          />
        </div>
        <div className="horizontal-card-item">
          {content?.title && (
            <p id="horizontal-card-heading" className="horizontal-card-heading">
              {content?.title}
            </p>
          )}
          {body?.map((bulletPoint, index) => {
            if (bulletPoint)
              return (
                <div key={index} style={{ display: "flex", gap: 5 }}>
                  {body?.length > 1 && <p>•</p>}
                  <p className="horizontal-card-description">{bulletPoint}</p>
                </div>
              );
          })}
          {/* {content?.body && (
            <p
              id="horizontal-card-description"
              className="horizontal-card-description"
            >
              {content?.body}
            </p>
          )} */}
        </div>
      </div>
      <style jsx={true}>{`
        @media only screen and (max-width: 990px) {
          .horizontal-card-description {
            font-size: 16px !important;
          }

          .horizontal-card-heading {
            font-size: 20px !important;
          }
        }

        .horizontal-card {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }

        .horizontal-card-heading {
          font-size: 26px;
          font-weight: bold;
          padding-top: 12px;
        }

        .horizontal-card-item {
          color: black;
          flex: 1 1 350px;
          padding: 12px;
        }

        .image {
          max-width: 470px;
          max-height: 350px;
        }

        .horizontal-card-description {
          font-size: 18px;
          text-align: justify;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          text-align: justify;
        }
      `}</style>
    </>
  );
};

export default HorizontalCard;
