import React from "react";
import BACK from "../../assets/services-bottom-back8.jpg";
import HorizontalCard from "../../components/HorizontalCard.js";

const LearnMore = ({ content }) => {
  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .learn-more-back {
          background-image: url(${BACK});
          background-size: 300%;
          background-position: center;
          background-repeat: no-repeat;
          color: #fff;
          overflow-x: hidden;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          position: relative;
        }

        .learn-more-content {
          margin-top: 10vh;
          padding: 2rem;
          border-radius: 15px;
        }

        .learn-more-heading {
          font-size: 3vw;
          font-weight: bold;
          text-align: center;
          color: #68755c;
        }

        .learn-more-line {
          width: 50%;
          margin: 1rem auto;
          border: 1px solid #fff;
        }

        .learn-more-para {
          font-size: 1.1vw;
          font-weight: 500;
          color: black;
          text-align: center;
        }

        .spinner-border {
          color: #fff;
        }

        @media screen and (max-width: 768px) {
          .learn-more-heading {
            font-size: 6vw;
          }

          .learn-more-para {
            font-size: 3vw;
          }
        }

        .section-heading {
          font-size: 2dvw;
          font-weight: bold;
          color: black;
        }
      `}</style>

      <div className="learn-more-back">
        <div className="learn-more-content container">
          <h1 id="learn-more-heading" className="learn-more-heading">
            Learn More
          </h1>
        </div>
        <div className="about-bottom-section container">
          {content?.map((item, index) => (
            <HorizontalCard
              key={item?.id}
              image={item?.image}
              content={{
                title: item?.name,
                body: item?.description,
              }}
              imageOnRight={index % 2 == 0}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LearnMore;
