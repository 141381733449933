import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import AlliedBank from "../../assets/abl-logo-22.png";
const TransactionModal = ({
  show,
  handleClose,
  handleTransactionSubmit,
  totalAmount,
}) => {
  const [transactionId, setTransactionId] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const transactionIdPattern = /^\d{12}$/; // Regular expression for 12-digit number
    if (!transactionId) {
      setError("Transaction ID is required");
    } else if (!transactionIdPattern.test(transactionId)) {
      setError("Transaction ID must be a 12-digit number");
    } else {
      setError("");
      handleTransactionSubmit(transactionId);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="transaction-modal"
      contentClassName="transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="checkout-heading">Confirm Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body className="striped-background px-md-5 py-md-4">
        <p className="fs-5 fw-bold">
          Please pay 10% Rs-{totalAmount}/- to any of these accounts provided
          below.
        </p>
        <div className="d-flex flex-column py-4">
          <div className="d-flex flex-wrap flex-row align-items-center justify-content-between px-sm-5">
            <img src={AlliedBank} width="250px" alt="allied-bank-logo" />
            <p>
              <strong>Account Title: </strong>
              <br />
              {process.env.REACT_APP_ALLIED_BANK_TITLE}
            </p>
            <p style={{ marginBottom: "5px !important" }}>
              <strong>IBAN: </strong>
              <br />
              {process.env.REACT_APP_ALLIED_BANK_IBAN}
            </p>
          </div>
          <div className="d-flex flex-wrap flex-row align-items-center justify-content-between px-sm-5">
            <img
              src="/assets/jazz-cash-logo.png"
              alt="JazzCash Logo"
              width="150px"
            />
            <p>
              <strong>Account Title: </strong>
              <br />
              {process.env.REACT_APP_JAZZCASH_ACCOUNT_TITLE}
            </p>
            <p style={{ color: "red" }}>
              <strong>Number: </strong>
              <br />
              {process.env.REACT_APP_JAZZCASH_ACCOUNT_NUMBER}
            </p>
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Label className="fw-bold">Transaction ID</Form.Label>
          <div className="d-flex align-items-baseline">
            <Form.Group controlId="transactionId" className="w-100">
              <Form.Control
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                isInvalid={!!error}
                className="w-100"
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="ms-3 learn-more-btn" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModal;
