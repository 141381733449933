import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Footer({
  brandDetails,
  aboutUs,
  services,
  socialAccounts,
}) {
  return (
    <>
      <style jsx="true">{`
        .footer-back {
          background: linear-gradient(to right, #000000, #121212);
        }
        .footer-heading {
          position: relative;
          display: inline-block;
        }
        .footer-heading::after {
          content: "";
          position: absolute;
          left: -20px;
          bottom: -15px;
          width: 79vw;
          height: 1px;
          background-color: rgb(46, 45, 45);
        }
        .about-column,
        .services-column,
        .social-media-links {
          cursor: pointer;
          text-decoration: none;
        }
        .footer-links {
          color: #f3c2af !important;
        }
        .footer-links:hover {
          color: #fff !important;
          transition: 0.8s all ease;
        }
        .disclaimer-heading {
          margin-bottom: 50px;
        }
        @media screen and (max-width: 1400px) {
          .disclaimer-heading {
            margin-bottom: 80px;
          }
        }
        @media screen and (max-width: 768px) {
          .disclaimer-heading {
            margin-bottom: 30px;
          }
        }
        .disclaimer-text {
          font-size: 0.9rem;
          color: #f3c2af !important;
        }
        .copyright-section {
          font-size: 1vw;
          color: #fff;
          background-color: #000000;
          text-align: center;
          padding: 25px;
          margin-top: 5%;
        }
        @media screen and (max-width: 989px) {
          .copyright-section {
            font-size: 3vw;
          }
        }
      `}</style>

      <footer className="footer-back pt-5">
        <div className="container px-md-5 px-3">
          <div className="row justify-content-between">
            {/* Column 1: About Salon */}
            <div className="col-md-2 mb-3 mb-md-0">
              <h3 className="footer-heading text-light mb-4 mb-md-5">
                {aboutUs[0].description}
              </h3>
              {aboutUs.slice(1).map((item, index) => (
                <Link to={item.extras} className="about-column" key={index}>
                  <p className="footer-links mb-2">{item.description}</p>
                </Link>
              ))}
            </div>
            {/* Column 2: Disclaimer */}
            <div className="col-md-4 mb-3 mb-md-0">
              <h3 className="text-light disclaimer-heading mt-3 mt-md-0">
                {services[0].description}
              </h3>
              <div className="mt-4 mt-md-4">
                <p
                  className="disclaimer-text mb-2"
                  dangerouslySetInnerHTML={{ __html: services[1].description }}
                ></p>
              </div>
            </div>
            {/* Column 3: Beauty Salon at a Glance */}
            <div className="col-md-4 mb-3 mb-md-0">
              <h3 className="text-light mb-4 mb-md-5 mt-3 mt-md-0">
                {brandDetails[0].description}
              </h3>
              <div>
                <h5 className="text-light mb-3 mt-4 mt-md-0">
                  {brandDetails[1].description}
                </h5>
                <div className="d-flex align-items-center mb-2">
                  <i
                    className={`fa ${brandDetails[8].extras} text-brand-secondary me-2`}
                  ></i>
                  <p className="text-light mb-0">
                    {brandDetails[8].description}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i
                    className={`fa ${brandDetails[2].extras} text-brand-secondary me-2`}
                  ></i>
                  <p className="text-light mb-0">
                    {brandDetails[2].description}
                  </p>
                </div>
                <h5 className="text-light mb-3 mb-md-4 mt-4 mt-md-4">
                  {brandDetails[3].name}
                </h5>
                <div className="d-flex align-items-center">
                  {socialAccounts.map((account, index) => (
                    <a
                      key={index}
                      href={account.url}
                      className="social-media-links"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className={`fa-brands ${account.icon_code} text-brand-secondary me-2 me-md-3 fs-3`}
                      ></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
        </div>
        <div className="copyright-section">
          <p className="mb-0">{brandDetails[9].description}</p>
        </div>
      </footer>
    </>
  );
}
