import React, { useEffect } from "react";
import Toast from "../../components/Toast/Toast.jsx";
import IMG from "../../assets/8.png";

const PrivacyPolicy = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .privacy-policy-back {
          overflow-x: hidden;

          .policy-top-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: relative;

            .services-image {
              width: 100%;
            }

            .privacy-policy-heading {
              position: absolute;
              top: 35%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #68755c;
              font-size: 3vw;
              font-weight: bold;
              text-align: center;
            }

            .privacy-policy-line {
              width: 4%;
              position: absolute;
              top: 48%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .privacy-policy-para {
              position: absolute;
              top: 65%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 60%;
              font-size: 1.1vw;
              font-weight: 500;
              text-align: center;
            }
          }

          .policy-bottom-section {
            .privacy-policy-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              padding: 5% 0% 5% 0%;
            }
          }

          .feedback-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 4rem;

            .feedback-heading {
              color: #68755c;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .feedback-line {
              width: 5%;
            }

            .feedback-para {
              font-size: 1vw;
              font-weight: 500;
            }

            .feedback-form {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 25px 0px 50px 0px;

              .submitted-text {
                color: #869672;
                font-size: 1.5vw;
                font-weight: bold;
              }
            }

            @media screen and (max-width: 768px) {
              .feedback-para {
                font-size: 3vw;
                margin-bottom: 30px;
              }

              .feedback-line {
                width: 10%;
              }

              .feedback-form {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 50px;

                .submitted-text {
                  font-size: 5vw;
                  margin-bottom: 25px;
                }

                .feedback-image-class {
                  .feedback-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-bottom: 10%;
                  }
                }
              }
            }
          }
        }
      `}</style>

      <div className="privacy-policy-back">
        <div className="policy-top-section">
          <img src={IMG} alt="" className="services-image" />
          <div className="container">
            <h1 className="privacy-policy-heading">Privacy Policy</h1>
            {content && <hr className="privacy-policy-line" />}
            <p className="privacy-policy-para">
              Protecting Your Privacy: Our Commitment to Transparency and
              Security at Lacigal - Your Trustworthy Partner in Beauty and
              Wellness
            </p>
          </div>
        </div>
        <div className="policy-bottom-section">
          <div className="container">
            <div className="privacy-policy-content">
              <h2>Soon...</h2>
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default PrivacyPolicy;
