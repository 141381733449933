import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";
import ContactUsForm from "../../pages/contactUsPage/Components/ContactUsForm";
import CONTACTUS from "../../assets/contact-us.png";

const ContactUS = ({ content }) => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    if (!captchaValue) {
      toast.error("Please verify reCAPTCHA");
      return;
    }

    setIsLoading(true);

    let payload = {
      ...values,
    };

    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("message", payload.message);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}service/contact-us/`,
        formData
      );
      if (response.data.error) {
        toast.error("Error!");
        return;
      }
      toast.success("Message Sent");
      setIsFormSubmitted(true);
    } catch (error) {
      toast.error("Unable to load contact us content", { id: "contact-us" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style jsx="true">{`
        .contact-us-heading {
          color: #68755c;
        }

        .contact-us-sub-heading {
          color: #121212;
          font-size: 1vw;
        }

        .contact-us-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding-top: 4rem;

          .contact-us-form {
            width: 88%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 50px;

            .submitted-text {
              color: #869672;
              font-size: 1.6vw;
              font-weight: bold;
            }

            .contact-us-image-class {
              .contact-us-img {
                width: 90%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          @media screen and (max-width: 768px) {
            .contact-us-form {
              width: 90%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 50px;

              .submitted-text {
                font-size: 5vw;
                margin-bottom: 25px;
              }

              .contact-us-image-class {
                .contact-us-img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }

        @media screen and (max-width: 989px) {
          .contact-us-sub-heading {
            color: #121212;
            font-size: 1.5vw;
          }
        }

        @media screen and (max-width: 768px) {
          .contact-us-sub-heading {
            color: #121212;
            font-size: 2vw;
          }
        }

        @media screen and (max-width: 480px) {
          .contact-us-sub-heading {
            color: #121212;
            font-size: 3vw;
          }
        }
      `}</style>

      <section className="py-5 services-section">
        <div className="container">
          <div className="row">
            <div className="mx-auto" style={{ maxWidth: "fit-content" }}>
              <h2 className="text-center m-4 mb-3 fw-bold contact-us-heading">
                {content[0].description}
              </h2>
              <hr className="bg-black mx-5"></hr>
            </div>
          </div>
          <p className="col-md-10 mx-auto text-center mb-3 fw-500 contact-us-sub-heading">
            {content[1].description}
          </p>
          <div className="contact-us-content">
            <div className="contact-us-form">
              {!isFormSubmitted ? (
                <ContactUsForm
                  setCaptchaValue={setCaptchaValue}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  content={content[3].description}
                />
              ) : (
                <div className="submitted-text">{content[2].description}</div>
              )}
              <p className="contact-us-image-class col-md-6">
                <img src={CONTACTUS} alt="" className="contact-us-img" />
              </p>
            </div>
          </div>
        </div>
      </section>

      <Toast />
    </>
  );
};

export default ContactUS;
