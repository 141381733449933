import React, { useState } from "react";
import Select from "react-select";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SubCategoryModal from "../../../components/Modal/SubCategoriesModal";

const ServiceCard = ({
  id,
  name,
  description,
  price,
  image,
  hasChildren,
  subCategories,
  hasSubCategories,
  isSelected,
  onSelectService,
}) => {
  const [quantity, setQuantity] = useState();
  const [showSubServices, setShowSubServices] = useState(false);
  // const [showOptions, setShowOptions] = useState(false);
  // const [selectedCard, setSelectedCard] = useState(false);

  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const handleQuantityChange = (selectedOption) => {
    setQuantity(selectedOption);
  };

  // const hasSubCategories=true
  // const subCategories = [
  //   {
  //     currency: "PKR",
  //     description: "Lorem ipsum dolor sit amet",
  //     discounted_price: "0.00",
  //     heading: "Organic Hair Glossing",
  //     id: 26,
  //     image: "https://lacigal-assets.s3.amazonaws.com/image-350x175_19.jpg",
  //     name: "Organic Hair Glossing",
  //     price: 2500,
  //     hasSubCategories: true,
  //   },
  //   // Add more objects as needed
  // ];
  return (
    <>
      <style jsx="true">{`
        .card-background-class.selected {
          border: 3px solid green;
          position: relative;
        }

        .selected-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          color: green;
          cursor: pointer;
          background: white;
          padding: 5px;
          border-radius: 50%;
          z-index:999
        }

        .card-background-class.selected.no-hover {
          &:hover {
            .card-image-class,
            .card-body-class {
              opacity: 1;
              transition: none;
            }
            .learn-more-btn{
              display:none
            }
            .quantity-selector {
              opacity: 0;
              transition: none;

              .btn-booking {
                opacity: 0;
                transition: none;
              }
            }
            .list-icon-2 {
              opacity: 0;
              transition: none;
            }
            transform: none;
          }
        }
        .card-background-class {
          width: 350px;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #f0f1f4;
          box-shadow: 0px 0px 15px -3px #f6a192;
          margin: 15px 15px 30px 15px;
          overflow: hidden;
          position: relative;
          transition: all 0.3s ease-in-out;

          .card-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .card-image-class {
            width: 350px;
            height: 175px;
            padding: 20px 20px 0px 20px;

            .card-team-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .card-body-class {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 20px 15px 20px;

            .card-title {
              color: #121212;
              font-size: 1.2vw;
              font-weight: bold;
              text-align: center;
            }

            .card-description {
              color: #121212;
              font-size: 0.9vw;
              text-align: justify;
              margin: 8px 0px 16px 0px;
            }

            .reviews {
              font-size: 1vw;
            }

            .card-price {
              color: #869672;
              font-size: 1.1vw !important;
              font-weight: 600;
              margin-bottom: 5px;
            }

            .list-icon {
              width: 100%;
              color: #121212;
              margin: 0;

              &:hover {
              }
            }
          }

          .more-detail-btn {
            font-size: 1vw;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(to bottom right, #000101, #323131);
            border: none;
            border-radius: 3px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 1% 5%;

            &:hover {
              color: #ffffff;
              background: linear-gradient(to bottom right, #000101, #000101);
              transition: 0.3s all ease-in-out;
            }
          }
          .learn-more-btn {
            font-size: 1vw;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(to top right, #68755c, #869672);
            border: none;
            border-radius: 3px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 1% 5%;

            &:hover {
              color: #121212;
              transition: 0.3s all ease-in-out;
            }
          }
          .list-icon-2 {
            width: 25%;
            display: flex;
            justify-content: space-around;
            cursor: pointer;
            opacity: 0;
            transform: rotateY(180deg);
            transition: all 0.3s ease-in-out;
            position: absolute;
            top: 30%;
            bottom: 60%;
          }

          .quantity-selector {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transform: rotateY(180deg);
            transition: all 0.3s ease-in-out;
            position: absolute;
            bottom: 40%;

            .person-selector {
              width: 75px;
            }

            .btn-booking {
              align-items: right;
              font-size: 0.9vw;
              font-weight: 500;
              color: #ffffff;
              background: linear-gradient(to top right, #68755c, #869672);
              border: none;
              border-radius: 5px;
              text-transform: uppercase;
              padding: 10px 10px;
              cursor: pointer;
              opacity: 0;
              position: absolute;
            }
          }

          &:hover {
            .card-image-class {
              opacity: 0;
              transition: all 0.5s ease-in-out;
            }

            .card-body-class {
              opacity: 0;
              transition: all 0.5s ease-in-out;
            }

            .quantity-selector {
              opacity: 1;
              transition: all 2s ease-in-out;

              .btn-booking {
                opacity: 1;
                transition: all 2s ease-in-out;

                &:hover {
                  color: #121212;
                  transition: 0.2s all ease-in-out;
                }
              }
            }

            .list-icon-2 {
              opacity: 1;
              transition: all 1.2s ease-in-out;
            }

            transition: all 1s ease-in-out;
            transform: rotateY(180deg);
            z-index: 1;
          }
        }

        @media screen and (max-width: 1430px) {
          .card-background-class {
            width: 315px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #f0f1f4;

            .card-image-class {
              width: 315px;
              height: 157px;
              padding: 20px 20px 0px 20px;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-body-class {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 20px 15px 20px;

              .card-title {
                color: #121212;
                font-size: 2vw;
                font-weight: bold;
                text-align: center;
              }

              .card-description {
                color: #121212;
                font-size: 1vw !important;
                text-align: justify;
                margin: 8px 0px 16px 0px;
              }

              .reviews {
                font-size: 2vw;
              }

              .card-price {
                color: #869672;
                font-size: 1.5vw !important;
                font-weight: 600;
                margin: 0;
              }
            }

            .btn-booking {
              font-size: 2.5vw;
            }
          }
        }

        @media screen and (max-width: 1225px) {
          .card-background-class {
            width: 255px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #f0f1f4;

            .card-image-class {
              width: 255px;
              height: 128px;
              padding: 20px 20px 0px 20px;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-body-class {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 20px 15px 20px;

              .card-title {
                color: #121212;
                font-size: 2vw;
                font-weight: bold;
                text-align: center;
              }

              .card-description {
                color: #121212;
                font-size: 1vw !important;
                text-align: justify;
                margin: 8px 0px 16px 0px;
              }

              .reviews {
                font-size: 2vw;
              }

              .card-price {
                color: #869672;
                font-size: 1.5vw !important;
                font-weight: 600;
                margin: 0;
              }
            }

            .btn-booking {
              font-size: 2.5vw;
            }
          }
        }

        @media screen and (max-width: 991px) {
          .card-background-class {
            width: 175px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #f0f1f4;

            .card-image-class {
              width: 175px;
              height: 87px;
              padding: 20px 20px 0px 20px;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-body-class {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 20px 15px 20px;

              .card-title {
                color: #121212;
                font-size: 1.5vw;
                font-weight: bold;
                text-align: center;
              }

              .card-description {
                color: #121212;
                font-size: 1vw !important;
                text-align: justify;
                margin: 8px 0px 16px 0px;
              }

              .reviews {
                font-size: 2vw;
              }

              .card-price {
                color: #869672;
                font-size: 1.2vw !important;
                font-weight: 600;
                margin: 0;
              }
            }

            .btn-booking {
              font-size: 2.5vw;
            }
          }
        }

        @media screen and (max-width: 768px) {
          .learn-more-btn {
            font-size: 2vw !important;
          }

          .more-detail-btn {
            font-size: 2vw !important;
          }
          .card-background-class {
            width: 275px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #f0f1f4;

            .card-image-class {
              width: 275px;
              height: 138px;
              padding: 20px 20px 0px 20px;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-body-class {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 20px 15px 20px;

              .card-title {
                color: #121212;
                font-size: 2.5vw;
                font-weight: bold;
                text-align: center;
              }

              .card-description {
                color: #121212;
                font-size: 1.8vw !important;
                text-align: justify;
                margin: 8px 0px 16px 0px;
              }

              .reviews {
                font-size: 2vw;
              }

              .card-price {
                color: #869672;
                font-size: 2.2vw !important;
                font-weight: 600;
                margin: 0;
              }
            }
          }
        }

        @media screen and (max-width: 480px) {
          .learn-more-btn {
            font-size: 2vw !important;
          }

          .more-detail-btn {
            font-size: 2vw !important;
          }
          .card-background-class {
            width: 250px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #f0f1f4;

            .card-image-class {
              width: 250px;
              height: 125px;
              padding: 20px 20px 0px 20px;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-body-class {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 15px 20px 15px 20px;

              .card-title {
                color: #121212;
                font-size: 4vw;
                font-weight: bold;
                text-align: center;
              }

              .card-description {
                color: #121212;
                font-size: 3vw !important;
                text-align: justify;
                margin: 8px 0px 16px 0px;
              }

              .reviews {
                font-size: 3vw;
              }

              .card-price {
                color: #869672;
                font-size: 3.5vw !important;
                font-weight: 600;
                margin-bottom: 5px;
              }

              .list-icon { 
                margin: 0;
              }
            }
          }
        }
      `}</style>

      <div
        className={`card-background-class ${
          isSelected ? "selected no-hover" : ""
        }`}
      >
        <div className="card-content">
          <div className="card-image-class">
            <img src={image} alt="" className="card-team-image" />
            {isSelected && (
              <div onClick={() => onSelectService(id)}>
              <IoMdCheckmarkCircleOutline
                className="selected-icon"
                size={34}
                
              />
              </div>
            )}
          </div>
          <div className="card-body-class">
            <h5 className="card-title">{name}</h5>
            <h6 className="card-description">{description}</h6>
            <h6 className="card-price">{price}</h6>
          </div>
        </div>
        <div className="card-content">
          <div className="list-icon-2 w-100 h-100 top-0 bottom-0">
            <div className="d-flex flex-column align-items-center justify-content-center w-100">
              {hasSubCategories && (
                <button
                  className="more-detail-btn p-3"
                  onClick={() => setShowSubServices(true)}
                >
                  View Sub Categories
                </button>
              )}
              <button
                className="learn-more-btn p-3 mt-3"
                onClick={() => onSelectService(id)}
              >
                {isSelected ? "Deselect" : "Book Now"}
              </button>
            </div>
          </div>
          {quantity && (
            <div className="quantity-selector">
              <Select
                className="person-selector"
                classNamePrefix="select"
                defaultValue={options[0]}
                onChange={handleQuantityChange}
                options={options}
                placeholder="No. of Persons"
              />
            </div>
          )}
        </div>
      </div>
      <SubCategoryModal
        show={showSubServices}
        onHide={() => setShowSubServices(false)}
        subCategory={subCategories}
      />
    </>
    // {/* <FileViewModal
    //   show={showSubServices}
    //   onHide={() => setShowSubServices(!showSubServices)}
    // /> */}
    //   {/* <FaRegSquarePlus size={18} onClick={() => setShowOptions(true)} />
    //   <IoDocumentTextOutline
    //   size={18}
    //   onClick={() => setShowSubServices(true)}
    // /> */}
    // <SubCategoryModal
    //       show={showSubServices}
    //       onHide={() => setShowSubServices(false)}
    //       subCategory={subCategories}
    //     />
    //   </>
  );
};

export default ServiceCard;
