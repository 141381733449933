import React from "react";

export default function Health({ data }) {
  return (
    <>
      <style jsx="true">{`
        .good-health-heading {
          color: #68755c;
        }

        .good-health-sub-heading {
          color: #121212;
          font-size: 1vw;
        }

        .good-health-heading-end {
          color: #121212 !important;
        }

        @media screen and (max-width: 989px) {
          .good-health-sub-heading {
            color: #121212;
            font-size: 1.5vw;
          }
        }

        @media screen and (max-width: 768px) {
          .good-health-sub-heading {
            color: #121212;
            font-size: 2vw;
          }
        }

        @media screen and (max-width: 480px) {
          .good-health-sub-heading {
            color: #121212;
            font-size: 3vw;
          }
        }
      `}</style>

      <div className="container mt-md-5 mt-3">
        <div className="row">
          <div className="mx-auto" style={{ maxWidth: "fit-content" }}>
            <h2
              className="text-center mb-3 fw-bold good-health-heading"
              dangerouslySetInnerHTML={{ __html: data[0].description }}
            ></h2>
            <hr className="bg-black mx-5"></hr>
          </div>
        </div>
        <p className="col-md-10 mx-auto text-center mb-md-5 mb-3 fw-500 good-health-sub-heading">
          {data[8].description}
        </p>
      </div>
      <section
        className="health-section py-4"
        style={{
          backgroundImage: `url(${data[9].image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="health-circle d-flex flex-column ">
          {data.slice(2, 5).map((item) => (
            <div
              className="health-item d-flex align-items-center mb-3"
              key={item.id}
              style={{ "--i": item.id }}
            >
              <div className="text-end">
                <h6 className="mt-4 fw-500">{item.name}</h6>
                <p className="fs-8 text-brand-gray">{item.description}</p>
              </div>
              <img src={item.image} alt={item.name} className="ms-3" />
            </div>
          ))}
        </div>
        <img src={data[1].image} className="mx-5" alt="" />
        <div className="health-circle d-flex flex-column">
          {data.slice(5, 8).map((item) => (
            <div
              className="health-item d-flex align-items-center mb-3"
              key={item.id}
              style={{ "--i": item.id }}
            >
              <img src={item.image} alt={item.name} className="me-3" />
              <div>
                <h6 className="mt-4 fw-500">{item.name}</h6>
                <p className="fs-8 text-brand-gray">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
