import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import YupSchema from "../../../schema/yupSchema";

const ContactUsForm = ({ setCaptchaValue, onSubmit, isLoading, content }) => {
  const [loading, setLoading] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupSchema.contact),
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  }

  return (
    <>
      <style jsx="true">{`
        .contact-form-back {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .contact-form-sub-back {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;

            .contact-form-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: left;
              align-items: left;
              text-align: left;

              .field-col {
                color: #000000;
                margin-bottom: 30px;
              }

              .input-text {
                font-size: 1vw;
              }

              .invalid-feedback {
                display: inline-block !important;
                font-style: italic !important;
              }

              .btn-contact-submit {
                font-size: 0.9vw;
                font-weight: 500;
                color: #ffffff;
                background: linear-gradient(#869672, #6c7a5f);
                border: none;
                border-radius: 3px;
                text-decoration: none;
                text-transform: uppercase;
                padding: 8px 30px;

                &:hover {
                  color: #121212;
                  transition: 0.3s all ease-in-out;
                }
              }

              @media screen and (max-width: 768px) {
                .input-text {
                  font-size: 2.5vw;
                }

                .btn-contact-submit {
                  font-size: 2vw;
                  padding: 4px 15px;
                }
              }

              @media screen and (max-width: 480px) {
                .input-text {
                  font-size: 3vw;
                }

                .btn-contact-submit {
                  font-size: 2vw;
                  padding: 4px 15px;
                }
              }
            }
          }

          @media screen and (max-width: 768px) {
            .contact-form-sub-back {
              width: 100%;
            }
          }
        }
      `}</style>

      <div className="contact-form-back col-md-6">
        <div className="contact-form-sub-back">
          <Form
            className="contact-form-class"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="field-col">
              <Form.Group as={Col}>
                <Form.Control
                  className="input-text"
                  type="text"
                  placeholder="Your name"
                  {...register("name")}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  className="input-text"
                  type="text"
                  placeholder="Your email"
                  {...register("email")}
                />
                {errors.email && (
                  <div className="invalid-feedback d-block">
                    {errors.email.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  className="input-text"
                  type="text"
                  placeholder="Phone Number"
                  {...register("phone_number")}
                />
                {errors.phone_number && (
                  <div className="invalid-feedback d-block">
                    {errors.phone_number.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  className="input-text"
                  as="textarea"
                  placeholder="Your message"
                  rows={3}
                  style={{ resize: "none" }}
                  {...register("message")}
                />
                {errors.message && (
                  <div className="invalid-feedback d-block">
                    {errors.message.message}
                  </div>
                )}
              </Form.Group>
            </Row>

            {process.env.REACT_APP_RECAPTCHA_ENABLER === "true" ? (
              <Row className="field-col">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </Row>
            ) : null}

            <Row className="field-col">
              <Form.Group
                className="d-flex justify-content-center"
                as={Col}
                controlId="formGridEmail"
              >
                <Button
                  type="submit"
                  className="btn-contact-submit d-flex justify-content-center align-items-center"
                >
                  {loading ? (
                    <div>
                      <Spinner
                        variant="light"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    content
                  )}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
