import React, { useState, useEffect } from "react";
import axios from "axios";
import AppRoutes from "../../routes/appRoutes";
import toast from "react-hot-toast";

const OurServices = ({ data }) => {
  const [apiData, setApiData] = useState();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedService, setSelectedService] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}service/services/`
        );
        setApiData(response.data?.services);
      } catch (error) {
        toast.error("Unable to load services", { id: "services-1" });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (apiData?.length > 1) setSelectedItem(apiData[0].id);
    else setSelectedItem(null);
  }, [apiData]);

  useEffect(() => {
    setIsImageLoading(true);
    setTimeout(() => {
      setIsImageLoading(false);
    }, 1000);
  }, [selectedItem]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <style jsx="true">{`
        .our-services-heading {
          color: #68755c;
        }

        .our-services-heading-end {
          color: #121212 !important;
        }

        .our-services-sub-heading {
          color: #121212;
          font-size: 1vw;
        }

        .custom-col {
          display: flex;
          justify-content: center;
          align-items: stretch;
          padding: 0.5rem;
        }

        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .accordion-class {
          width: 350px;
          height: 350px;
          border-radius: 5px;
          background-color: white;
          overflow-y: scroll;

          .accordion-items-class {
            font-size: 1vw;
            border-top: 1px solid #121212;
            cursor: pointer;
          }
        }

        .services-img-class {
          width: 350px;
          height: 350px;
          border-radius: 5px;
          border: 2px solid #68755c;
          opacity: 1;
          transition: border 0.5s linear;

          &.fade-out-class {
            opacity: 0;
            transition: opacity 0.2s linear;
          }

          .services-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
            opacity: 1;
            transition: all 3.5s linear;

            &.fade-out-class {
              opacity: 0;
              transition: opacity 0.2s linear;
            }
          }
        }

        .service-details-class {
          width: 350px;
          height: auto;
          display: flex;
          flex-direction: column;
          text-align: left;
          opacity: 1;
          transition: all 0.5s linear;

          &.fade-out-class {
            opacity: 0;
            transition: opacity 0.2s linear;
          }

          .services-price {
            color: #68755c;
            font-size: 1.3vw;
            font-weight: 600;
          }

          .services-description {
            font-size: 1vw;
          }

          .read-more-link {
            font-size: 0.9vw;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(#869672, #6c7a5f);
            border-radius: 3px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 8px 30px;

            &:hover {
              color: #121212;
              transition: 0.3s all ease-in-out;
            }
          }
        }

        @media screen and (max-width: 989px) {
          .our-services-sub-heading {
            color: #121212;
            font-size: 1.5vw;
          }

          .accordion-items-class {
            font-size: 1.7vw !important;
          }

          .service-details-class {
            align-items: center;
            text-align: center !important;
          }
        }

        @media screen and (max-width: 768px) {
          .our-services-sub-heading {
            color: #121212;
            font-size: 2vw;
          }

          .accordion-items-class {
            font-size: 2vw !important;
          }

          .service-details-class {
            align-items: center;
            text-align: center !important;

            .services-price {
              font-size: 3.2vw !important;
            }

            .services-description {
              font-size: 2.8vw !important;
            }

            .read-more-link {
              font-size: 1.8vw;
              padding: 5px 10px;
            }
          }
        }

        @media screen and (max-width: 480px) {
          .our-services-sub-heading {
            color: #121212;
            font-size: 3vw;
          }

          .accordion-items-class {
            font-size: 3vw !important;
          }

          .service-details-class {
            align-items: center;
            text-align: center !important;

            .services-price {
              font-size: 3.2vw !important;
            }

            .services-description {
              font-size: 2.8vw !important;
            }

            .read-more-link {
              font-size: 1.8vw;
              padding: 5px 10px;
            }
          }
        }
      `}</style>

      <section
        className="py-5 services-section"
        style={{
          backgroundImage: `url(${data[8]?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h2
            className="text-center m-4 mb-3 fw-bold our-services-heading"
            dangerouslySetInnerHTML={{ __html: data[0].description }}
          />
          <hr />
          <p className="col-md-10 mx-auto text-center mb-md-5 mb-3 fw-500 our-services-sub-heading">
            {data[7].description}
          </p>

          {apiData && (
            <div className="d-flex row justify-content-center">
              <div className="col-xs-8 col-sm-8 col-md-7 col-lg-7 col-xl-4 custom-col">
                <div className="accordion-class">
                  {apiData?.map((item, index) => (
                    <div
                      key={item.id}
                      className={`accordion-items-class d-flex align-items-center justify-content-between ${
                        selectedItem === item.id ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(item.id)}
                    >
                      <h6 className="py-3 ms-3 m-1 fw-bold" id={item.id}>
                        {item.name}
                      </h6>
                      {selectedItem === item.id && (
                        <i className="fa-solid fa-chevron-right me-3 fw-bold text-brand-secondary"></i>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-xs-8 col-sm-8 col-md-7 col-lg-7 col-xl-4 custom-col">
                <div
                  className={`services-img-class ${
                    isImageLoading ? "fade-out-class" : ""
                  }`}
                >
                  <img
                    src={
                      apiData?.find((item) => item.id === selectedItem)?.image
                    }
                    alt="Selected Item"
                    className={`img-fluid services-img ${
                      isImageLoading ? "fade-out-class" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="col-xs-8 col-sm-8 col-md-7 col-lg-7 col-xl-4 custom-col">
                {selectedItem && (
                  <div
                    className={`service-details-class ${
                      isImageLoading ? "fade-out-class" : ""
                    }`}
                  >
                    <div>
                      <h3 className="">
                        {
                          apiData?.find((item) => item.id === selectedItem)
                            ?.name
                        }
                      </h3>
                      <p className="services-price">
                        {
                          apiData?.find((item) => item.id === selectedItem)
                            ?.price_range
                        }
                      </p>
                      <p className="services-description">
                        {
                          apiData?.find((item) => item.id === selectedItem)
                            ?.description
                        }
                      </p>
                    </div>
                    <div className="w-100">
                      <a
                        href={`${AppRoutes.SERVICES}?id=${selectedItem}`}
                        className="read-more-link"
                      >
                        {data[9].description}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <p
            className="col-md-10 mx-auto text-center mt-md-5 mt-3 fw-500 disclaimer"
            style={{ color: "#a67c00", fontSize: "1vw" }}
          >
            {data[10].description}
          </p>
        </div>
      </section>
    </>
  );
};

export default OurServices;
