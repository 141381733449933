import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ show, onHide, onConfirm,isCancelLoading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Cancellation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel the appointment?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {isCancelLoading ? (
                      <span
                        className="spinner-border spinner-border-sm mx-md-4 mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Yes, Cancel"
                    )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
