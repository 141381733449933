const AppRoutes = {
  HOME: "/",
  SERVICES: "/services",
  DEALS: "/deals",
  OUR_EXPERTS: "/our-experts",
  CONTACT_US: "/contact-us",
  CHECKOUT: "/checkout",
  appointments: "/appointments",
  CAREERS: "/careers",
  FEEDBACK: "/feedback",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_POLICY: "/terms-policy",
  ABOUT_US: "/about-us",
  LEARN_MORE: "/learn-more",
};

export default AppRoutes;
