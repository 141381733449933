import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import YupSchema from "../../../schema/yupSchema";
import { toast } from "react-toastify";

const HiringForm = ({ setCaptchaValue, onSubmit, isLoading, content }) => {
  const [loading, setLoading] = useState();
  const [designations, setDesignations] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupSchema.hiring),
  });

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const designationsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}hiring/designations/`
        );

        const sortedData = designationsResponse?.data.sort((a, b) => {
          const jobTitleA = a.job_title.toUpperCase();
          const jobTitleB = b.job_title.toUpperCase();
          if (jobTitleA < jobTitleB) {
            return -1;
          }
          if (jobTitleA > jobTitleB) {
            return 1;
          }
          return 0;
        });

        setDesignations(sortedData);
      } catch (error) {
        toast.error("Unable to load designations", { id: "load-designations" });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <style jsx="true">{`
        .hiring-form-back {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .hiring-form-sub-back {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;

            .hiring-form-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: left;
              align-items: left;
              text-align: left;

              .field-col {
                color: #000000;
                margin-bottom: 30px;
              }

              .invalid-feedback {
                display: inline-block !important;
                font-style: italic !important;
              }

              .btn-hiring-submit {
                font-size: 1vw;
                font-weight: 500;
                color: #ffffff;
                background: linear-gradient(#869672, #6c7a5f);
                border: none;
                border-radius: 3px;
                text-decoration: none;
                text-transform: uppercase;
                padding: 8px 30px;

                &:hover {
                  color: #121212;
                  transition: 0.3s all ease-in-out;
                }
              }

              @media screen and (max-width: 768px) {
                  .btn-hiring-submit {
                    font-size: 2vw;
                    padding: 5px 15px;
                  }
                }
              }
      
              @media screen and (max-width: 480px) {
                  .btn-hiring-submit {
                    font-size: 2vw;
                    padding: 5px 15px;
                  }
                }
              }
            }
          }
        }
      `}</style>

      <div className="hiring-form-back col-md-6">
        <div className="hiring-form-sub-back">
          <Form className="hiring-form-class" onSubmit={handleSubmit(onSubmit)}>
            <Row className="field-col">
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Your name"
                  {...register("name")}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  type="text"
                  placeholder="Your email"
                  {...register("email")}
                />
                {errors.email && (
                  <div className="invalid-feedback d-block">
                    {errors.email.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  type="text"
                  placeholder="Your phone number"
                  {...register("phone_number")}
                />
                {errors.phone_number && (
                  <div className="invalid-feedback d-block">
                    {errors.phone_number.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  as="textarea"
                  placeholder="Your message"
                  rows={3}
                  style={{ resize: "none" }}
                  {...register("message")}
                />
                {errors.message && (
                  <div className="invalid-feedback d-block">
                    {errors.message.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col}>
                <Form.Control
                  as="select"
                  {...register("position_applying_for")}
                >
                  <option value="" disabled selected>
                    Select Designation
                  </option>
                  {designations &&
                    designations.map((key, index) => (
                      <option value={key?.id}>{key?.job_title}</option>
                    ))}
                </Form.Control>
                {errors.position_applying_for && (
                  <div className="invalid-feedback d-block">
                    {errors.position_applying_for.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="field-col">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  type="file"
                  required={false}
                  {...register("upload_cv")}
                />
                {errors.upload_cv && (
                  <div className="invalid-feedback d-block">
                    {errors.upload_cv.message}
                  </div>
                )}
              </Form.Group>
            </Row>

            {process.env.REACT_APP_RECAPTCHA_ENABLER === "true" ? (
              <Row className="field-col">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </Row>
            ) : null}

            <Row className="field-col">
              <Form.Group
                className="d-flex justify-content-center"
                as={Col}
                controlId="formGridEmail"
              >
                <Button
                  type="submit"
                  className="btn-hiring-submit d-flex justify-content-center align-items-center"
                >
                  {loading ? (
                    <div>
                      <Spinner
                        variant="light"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    content
                  )}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default HiringForm;
