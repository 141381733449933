import React from "react";
import Modal from "react-bootstrap/Modal";

const FileViewModal = (props) => {
  const { file, fileType } = props;

  return (
    <>
      <style jsx="true">{`
        .modal-content {
          background-color: transparent;
        }

        .modal-header {
          padding: 3%;
          background-color: rgb(255, 255, 255, 1);
        }

        .modal-body {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(255, 255, 255, 0.3);
        }

        .video-container {
          max-width: 100%;
          max-height: 100%;
        }

        .video-container video {
          width: 100%;
          height: auto;
        }
      `}</style>

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-class"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {fileType === "image" ? (
            <img src={file} alt="" className="img-fluid img-thumbnail" />
          ) : fileType === "video" ? (
            <div className="video-container">
              <video controls>
                <source src={file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileViewModal;
