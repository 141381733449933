// useStartExpanded.js
import { useEffect } from 'react';

export const useStartExpanded = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useStartExpanded.pluginName = 'useStartExpanded';

const useInstance = (instance) => {
  const {
    state: { startExpanded },
    toggleAllRowsExpanded,
  } = instance;

  useEffect(() => {
    if (startExpanded) toggleAllRowsExpanded(true);
  }, [startExpanded, toggleAllRowsExpanded]);
};
