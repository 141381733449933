import React, { useState, useEffect } from "react";
import axios from "axios";
import TestimonialCarousel from "../Carousel/TestimonialCarousel/TestimonialCarousel";
import BACKGROUND from "../../assets/80.png";
import toast from "react-hot-toast";

const Testimonials = ({ data }) => {
  const [testimonials, setTestimonials] = useState([1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}testimonials/feedback/`
        );

        setTestimonials(responseData?.data);
      } catch (error) {
        toast.error("Unable to load feedback content", { id: "feedback" });
      }
    };

    fetchData();
  }, []);
  const carouselItems = testimonials.map((item, index) => ({
    feedback: item?.message,
    name: item?.name,
    location: item?.location,
    file: item?.file,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <style jsx="true">{`
        .testimonials-heading {
          color: #68755c;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .testimonials-sub-heading {
          color: #121212;
          font-size: 1vw;
        }

        .contact-us-line {
          width: 15%;
        }

        .contact-us-para {
          font-size: 1vw;
          font-weight: 500;
        }

        .image-column {
          .our-team-img {
            width: 80%;
            height: 100%;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 989px) {
          .testimonials-sub-heading {
            color: #121212;
            font-size: 1.5vw;
          }
        }

        @media screen and (max-width: 768px) {
          .testimonials-sub-heading {
            color: #121212;
            font-size: 2vw;
          }

          .our-team-back {
            .contact-us-heading {
              margin-top: 25px;
            }

            .contact-us-para {
              font-size: 3vw;
              margin-bottom: 30px;
            }

            .contact-us-line {
              width: 10%;
            }

            .image-column {
              .our-team-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        @media screen and (max-width: 480px) {
          .testimonials-sub-heading {
            color: #121212;
            font-size: 3vw;
          }
        }

        .team-section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          margin-top: 15px;
        }
      `}</style>

      <section
        className="py-3"
        style={{
          backgroundImage: `url(${BACKGROUND})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="mx-auto" style={{ maxWidth: "fit-content" }}>
              <h2 className="text-center m-4 mb-3 fw-bold testimonials-heading">
                Testimonials
              </h2>
              <hr className="bg-black mx-5"></hr>
            </div>
          </div>
          <p className="col-md-10 mx-auto text-center mb-md-5 mb-3 fw-500 testimonials-sub-heading">
            {data
              ? (data?.find((item) => item?.name === "Feedback Sub Heading"))
                  .description
              : ""}
          </p>

          {carouselItems && <TestimonialCarousel items={carouselItems} />}
        </div>
      </section>
    </>
  );
};

export default Testimonials;
