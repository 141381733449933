import React from "react";
import { Modal, Button } from "react-bootstrap";
import OTPInput from "react-otp-input";

const OTPModal = ({
  show,
  onHide,
  otp,
  setOtp,
  handleVerifyOTP,
  isOTPLoading,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Enter OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full otp-input-container justify-content-center m-md-5 mx-3">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props, index) => <input {...props} type="number" />}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p
          style={{ fontSize: "12px", fontStyle: "italic", fontWeight: "bold" }}
        >
          NOTE: Please check your registered email for OTP
        </p>
        <button
          variant="secondary"
          className="more-detail-btn py-2 px-3"
          onClick={onHide}
        >
          Close
        </button>
        <button
          variant="primary"
          onClick={handleVerifyOTP}
          className="learn-more-btn py-2 px-3"
          disabled={otp.length < 6 || isOTPLoading}
        >
          {isOTPLoading ? (
            <span
              className="spinner-border spinner-border-sm  mx-3"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Submit"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default OTPModal;
