import React, { useState, useEffect, useMemo, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./modelStyles.css";
import HierarchicalTable from "../Tables/HirarichalTables";
import DialogueBox from "../DialogueBox";
import { UpdateCartContext } from "../../context/UpdateCartContext";

const ConfirmBookingDetails = ({
  show,
  onHide,
  selectedServiceIds,
  service,
}) => {
  const navigate = useNavigate();
  const [numPersons, setNumPersons] = useState({});
  const [combinedServices, setCombinedServices] = useState([]);
  // update card
  const { updateCart, setUpdateCart } = useContext(UpdateCartContext);

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : [];
  };

  useEffect(() => {
    const storedServices = getFromLocalStorage("selectedServices");
    const storedNumPersons = getFromLocalStorage("numPersons");
    const initialNumPersons = selectedServiceIds.reduce((acc, id) => {
      acc[id] = storedNumPersons[id] || 1;
      return acc;
    }, {});

    storedServices?.forEach((savedService) => {
      savedService.subRows.forEach((subService) => {
        initialNumPersons[subService.id] =
          subService.no_of_persons || storedNumPersons[subService.id] || 1;
      });
    });

    setNumPersons(initialNumPersons);

    const newSelectedData = {
      id: service.id,
      name: service.name,
      description: service.description,
      isService: true,
      subRows: service.categories
        .map((category) => ({
          id: category.id,
          name: category.name,
          isCategory: true,
          subRows: category.sub_services
            .filter((subService) => selectedServiceIds.includes(subService.id))
            .map((subService) => ({
              ...subService,
              parentName: category.name,
              no_of_persons: initialNumPersons[subService.id] || 1,
            })),
        }))
        .filter((category) => category.subRows.length > 0),
    };

    let combinedData = storedServices ? [...storedServices] : [];

    const existingServiceIndex = combinedData.findIndex(
      (s) => s.id === newSelectedData.id
    );

    if (existingServiceIndex > -1) {
      combinedData[existingServiceIndex] = {
        ...combinedData[existingServiceIndex],
        subRows: combinedData[existingServiceIndex].subRows.map(
          (storedCategory) => {
            const newCategory = newSelectedData.subRows.find(
              (newCat) => newCat.id === storedCategory.id
            );

            return newCategory
              ? {
                  ...storedCategory,
                  subRows: [
                    ...storedCategory.subRows.filter((storedSubService) =>
                      selectedServiceIds.includes(storedSubService.id)
                    ),
                    ...newCategory.subRows.filter(
                      (newSubService) =>
                        !storedCategory.subRows.find(
                          (storedSubService) =>
                            storedSubService.id === newSubService.id
                        )
                    ),
                  ],
                }
              : {
                  ...storedCategory,
                  subRows: storedCategory.subRows.filter((storedSubService) =>
                    selectedServiceIds.includes(storedSubService.id)
                  ),
                };
          }
        ),
      };

      newSelectedData.subRows.forEach((newCategory) => {
        if (
          !combinedData[existingServiceIndex].subRows.find(
            (storedCategory) => storedCategory.id === newCategory.id
          )
        ) {
          combinedData[existingServiceIndex].subRows.push(newCategory);
        }
      });
    } else {
      combinedData.push(newSelectedData);
    }

    combinedData = combinedData
      .map((service) => ({
        ...service,
        subRows: service.subRows.filter(
          (category) => category.subRows.length > 0
        ),
      }))
      .filter((service) => service.subRows.length > 0);

    setCombinedServices(combinedData);
    saveToLocalStorage("selectedServices", combinedData);
    saveToLocalStorage("numPersons", initialNumPersons);
  }, [selectedServiceIds, service]);

  const handleIncrement = (id) => {
    setNumPersons((prev) => {
      const updated = { ...prev, [id]: (prev[id] || 1) + 1 };
      saveToLocalStorage("numPersons", updated);
      return updated;
    });
  };

  const handleDecrement = (id) => {
    setNumPersons((prev) => {
      const updated = { ...prev, [id]: Math.max((prev[id] || 1) - 1, 1) };
      saveToLocalStorage("numPersons", updated);
      return updated;
    });
  };

  const totalAmount = useMemo(() => {
    return combinedServices.reduce((total, service) => {
      return (
        total +
        service.subRows.reduce((catTotal, subService) => {
          return (
            catTotal +
            subService.subRows.reduce((subCatTotal, subService) => {
              const price = parseFloat(subService.price);
              const quantity = numPersons[subService.id] || 1;
              const subTotal = price * quantity;
              return subCatTotal + subTotal;
            }, 0)
          );
        }, 0)
      );
    }, 0);
  }, [combinedServices, numPersons]);

  const filteredService = useMemo(() => {
    return combinedServices.map((service) => {
      const newService = {
        ...service,
        categories: service.subRows.map((category) => {
          const newCategory = {
            ...category,
            sub_services: category.subRows.map((subService) => ({
              ...subService,
              no_of_persons: numPersons[subService.id] || 1,
            })),
          };
          delete newCategory.subRows;
          delete newCategory.isCategory;
          return newCategory;
        }),
      };
      delete newService.subRows;
      delete newService.isService;
      return newService;
    });
  }, [combinedServices, numPersons]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          const indent = row.depth * 20;
          return (
            <span style={{ paddingLeft: `${indent}px` }}>
              {row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  {row.isExpanded ? "▼" : "▶"}
                </span>
              ) : (
                " "
              )}
              {row.original.isService ? (
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {value}
                </span>
              ) : row.original.isCategory ? (
                <span style={{ fontWeight: "bold" }}>{value}</span>
              ) : (
                value
              )}
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Persons",
        accessor: "id",
        Cell: ({ row, value }) =>
          !row.original.isCategory &&
          !row.original.isService && (
            <div className="d-flex align-items-center justify-content-around">
              <button
                className="learn-more-btn"
                onClick={() => handleDecrement(value)}
              >
                -
              </button>
              <span>{numPersons[value]}</span>
              <button
                className="learn-more-btn"
                onClick={() => handleIncrement(value)}
              >
                +
              </button>
            </div>
          ),
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: ({ row }) => {
          if (!row.original.isCategory && !row.original.isService) {
            const price = row.original.price;
            const tnumPersons = numPersons[row.original.id] || 1;
            return `${(price * tnumPersons).toFixed(2)}`;
          }
          return null;
        },
      },
    ],
    [numPersons, handleIncrement, handleDecrement]
  );

  const handleCheckout = () => {
    const servicesWithPersons = combinedServices.map((service) => {
      return {
        ...service,
        subRows: service.subRows.map((subService) => ({
          ...subService,
          numPersons: numPersons[subService.id],
        })),
      };
    });
    navigate("/checkout", {
      state: {
        selectedServices: servicesWithPersons,
        selectedData: combinedServices,
        numPersons: numPersons,
        filteredService: filteredService,
      },
    });
  };

  // const onCancel = () => {
  //   toast((t) => (
  //     <span className="py-3">
  //       <p className="fs-6 fw-bold">Are you sure you want to cancel it?</p>
  //       <p className="fs-6" style={{ fontWeight: "600" }}>
  //         because if you cancel it, all your selected items will be cleared.
  //       </p>
  //       <Button
  //         className="learn-more-btn"
  //         onClick={() => {
  //           localStorage.clear();
  //           navigate("/");
  //           toast.dismiss(t.id);
  //         }}
  //       >
  //         Yes
  //       </Button>
  //       <Button
  //         className="more-detail-btn ms-2"
  //         onClick={() => toast.dismiss(t.id)}
  //       >
  //         Cancal
  //       </Button>
  //     </span>
  //   ));
  // };

  const showDialogueBox = () => {
    DialogueBox(
      "Are you sure you want to cancel it?",
      "if you cancel it, all your selected items will be cleared",
      () => {
        localStorage.removeItem("selectedServiceIds");
        localStorage.removeItem("selectedServices");
        setUpdateCart(!updateCart);
        navigate("/");
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Service Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HierarchicalTable selectedData={combinedServices} columns={columns} />
        <div className="text-right">
          <h5>Total Amount: {totalAmount.toFixed(2)}</h5>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="more-detail-btn" onClick={onHide}>
          Close
        </Button>
        {parseInt(totalAmount) > 0 && (
          <Button className="more-detail-btn" onClick={showDialogueBox}>
            Cancel
          </Button>
        )}
        <Button className="learn-more-btn" onClick={handleCheckout}>
          Checkout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBookingDetails;
