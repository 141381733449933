import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Form, Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import HierarchicalTable from "../components/Tables/HirarichalTables";
import SuccessModal from "../components/Modal/SuccessModel";
import ReCAPTCHA from "react-google-recaptcha";
import DialogueBox from "../components/DialogueBox";
import TransactionModal from "../components/Modal/TransactionModal";
import { UpdateCartContext } from "../context/UpdateCartContext";

const CheckoutPage = ({ setSelectedDealIds, setDeals }) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModalTransaction, setShowModalTransaction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [bookingData, setBookingData] = useState(null);
  // update cart
  const { updateCart, setUpdateCart } = useContext(UpdateCartContext);
  const navigate = useNavigate();
  const {
    selectedServices,
    selectedData,
    numPersons,
    selectedDeals,
    filteredService,
  } = location.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          const indent = row.depth * 20;
          return (
            <span style={{ paddingLeft: `${indent}px` }}>
              {row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  {row.isExpanded ? "▼" : "▶"}
                </span>
              ) : (
                " "
              )}
              {row.original.isService ? (
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {value}
                </span>
              ) : (
                value
              )}
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ row }) => {
          if (row.original.isService) {
            return (
              <div>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "red",
                    marginRight: "10px",
                  }}
                >
                  {row.original.price.toFixed(2)}
                </span>
                <span>{row.original.discounted_price.toFixed(2)}</span>
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: "Persons",
        accessor: "persons",
        Cell: ({ row }) => {
          if (row.original.isService) {
            return (
              <div>
                <span style={{ margin: "0 10px" }}>{row.original.persons}</span>
              </div>
            );
          }
          return null;
        },
      },
    ],
    []
  );
  const serviceColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          const indent = row.depth * 20;
          return (
            <span style={{ paddingLeft: `${indent}px` }}>
              {row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  {row.isExpanded ? "▼" : "▶"}
                </span>
              ) : (
                " "
              )}
              {row.original.isService ? (
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {value}
                </span>
              ) : row.original.isCategory ? (
                <span style={{ fontWeight: "bold" }}>{value}</span>
              ) : (
                value
              )}
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Persons",
        accessor: "id",
        Cell: ({ row, value }) =>
          !row.original.isCategory &&
          !row.original.isService && (
            <div className="d-flex align-items-center justify-content-around">
              <span>{numPersons[value]}</span>
            </div>
          ),
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: ({ row }) => {
          if (!row.original.isCategory && !row.original.isService) {
            const price = row.original.price;
            const tnumPersons = numPersons[row.original.id] || 1;
            return `${(price * tnumPersons).toFixed(2)}`;
          }
          return null;
        },
      },
    ],
    [numPersons]
  );
  const totalAmount = selectedDeals
    ? selectedDeals.reduce((total, deals) => {
        return total + deals.discounted_price * (deals.numPersons || 1);
      }, 0)
    : selectedServices.reduce((total, service) => {
        return (
          total +
          service.subRows.reduce((catTotal, subService) => {
            return (
              catTotal +
              subService.subRows.reduce((subCatTotal, subService) => {
                const price = parseFloat(subService.price);
                const quantity = numPersons[subService.id] || 1;
                const subTotal = price * quantity;
                return subCatTotal + subTotal;
              }, 0)
            );
          }, 0)
        );
      }, 0);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    date: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    number: "",
    date: "",
    email: "",
  });
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleTransactionSubmit = async (transactionId) => {
    try {
      const finalBookingData = {
        ...bookingData,
        client: {
          ...bookingData.client,
          transaction_id: transactionId,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}booking/construct-booking/`,
        finalBookingData
      );
      if (response.data.error) {
        toast.error("Error!");
      } else {
        toast.success("Transaction Confirmed");
        setBookingId(response.data?.booking_data?.booking_id);
        setShowModalTransaction(false);
        setShowModal(true);
        localStorage.removeItem("selectedServices");
        localStorage.removeItem("numPersons");
        localStorage.removeItem("selectedServiceIds");
        setSelectedDealIds([]);
        setDeals([]);
      }
    } catch (error) {
      toast.error("Unable to book appointment", { id: "book-appointment" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let valid = true;
    const newErrors = { ...formErrors };

    for (const field in formData) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
        valid = false;
      } else if (
        field === "number" &&
        !/^(\+?92|0)\d{10}$/.test(formData[field])
      ) {
        newErrors[field] = "Please enter a valid phone number";
        valid = false;
      } else if (field === "email" && !/\S+@\S+\.\S+/.test(formData[field])) {
        newErrors[field] = "Please enter a valid email address";
        valid = false;
      }
    }

    if (!valid) {
      setFormErrors(newErrors);
      setIsLoading(false);
      return;
    } else {
      setIsLoading(false);
      if (process.env.REACT_APP_RECAPTCHA_ENABLER === "true") {
        if (!captchaValue) {
          toast.error("Are you a human?");
          return;
        }
      }
    }

    const newBookingData = {
      client: {
        name: formData.name,
        email_address: formData.email,
        phone_no: formData.number,
      },
      booking: {
        booking_date: formData.date,
        payment_status: "PENDING",
        total_price: totalAmount,
        selected_items: selectedDeals
          ? {
              deals: selectedDeals.map((deal) => ({
                id: deal.id,
                name: deal.name,
                discounted_price: deal.discounted_price,
                numPersons: deal.numPersons,
              })),
            }
          : {
              services: [...filteredService],
            },
      },
    };

    setBookingData(newBookingData);
    setShowModalTransaction(true);
  };

  const showDialogueBox = () => {
    DialogueBox(
      "Are you sure you want to cancel it?",
      "if you cancel it, all your selected items will be cleared",
      () => {
        localStorage.removeItem("selectedServiceIds");
        localStorage.removeItem("selectedServices");
        setUpdateCart(!updateCart);
        navigate("/");
      }
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="checkout-page">
        <div className="container py-md-5 py-3">
          <h2 className="text-center checkout-heading my-md-5 my-3">
            Checkout
          </h2>
          <HierarchicalTable
            selectedData={selectedData}
            columns={selectedDeals ? columns : serviceColumns}
          />
          <div className="text-right">
            <h5 className="text-end mt-md-4 mt-3 me-2">
              Total Amount: {totalAmount}
            </h5>
          </div>
          <Form onSubmit={handleSubmit} className="mb-4">
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    isInvalid={!!formErrors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                    isInvalid={!!formErrors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Group controlId="formNumber">
                  <Form.Label>Number </Form.Label>
                  <Form.Control
                    type="tel"
                    name="number"
                    placeholder="(e.g +92-xxx-xxxxxxx, No spaces or dashesh)"
                    value={formData.number}
                    onChange={handleChange}
                    required
                    isInvalid={!!formErrors.number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.number}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="abc@mail.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    isInvalid={!!formErrors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {process.env.REACT_APP_RECAPTCHA_ENABLER === "true" ? (
                <Row className="field-col mt-md-4">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </Row>
              ) : null}
              <div className="d-flex justify-content-end mt-3">
                {parseInt(totalAmount) > 0 && (
                  <Button
                    className="more-detail-btn me-2"
                    onClick={() => showDialogueBox()}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  setCaptchaValue={setCaptchaValue}
                  className="learn-more-btn px-3 py-3"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm mx-md-5 mx-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Confirm Booking"
                  )}
                </Button>
              </div>
            </Row>
          </Form>
        </div>
        <SuccessModal
          show={showModal}
          handleClose={handleCloseModal}
          bookingId={bookingId}
        />
        <TransactionModal
          show={showModalTransaction}
          handleClose={() => setShowModalTransaction(false)}
          handleTransactionSubmit={handleTransactionSubmit}
          totalAmount={(totalAmount / 100) * 10}
        />
      </div>
    </>
  );
};

export default CheckoutPage;
