import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ServicesComponent from "./Components/ServicesComponent";
import BACK from "../../assets/services-bottom-back8.jpg";
import ConfirmBookingDetails from "../../components/Modal/confirmBookingDetails";
import { ModalContext } from "../../context/Modal.Context";
import { UpdateCartContext } from "../../context/UpdateCartContext";
import { toast } from "react-toastify";

const OurServices = ({ content }) => {
  const banners = content;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceID = searchParams.get("id");
  const { showModal, setShowModal } = useContext(ModalContext);
  const { setUpdateCart, updateCart } = useContext(UpdateCartContext);
  // const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState();
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}service/services/?id=${serviceID}`
        );
        setService(apiResponse?.data?.services[0]);
      } catch (error) {
        toast.error("Unable to load requested service", { id: "load-service"});
      }
    };

    fetchData();
  }, [serviceID]);

  useEffect(() => {
    if (service) {
      setCategoriesList(service?.categories);
    }
  }, [service]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedSelectedServiceIds =
      JSON.parse(localStorage.getItem("selectedServiceIds")) || [];
    setSelectedServiceIds(storedSelectedServiceIds);
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      localStorage.setItem(
        "selectedServiceIds",
        JSON.stringify(selectedServiceIds)
      );
      // just trigger the card update
      // it has nothing to do with cart item numbers
      setUpdateCart(!updateCart);
    }
  }, [selectedServiceIds, isInitialized]);

  const handleSelectService = (id) => {
    setSelectedServiceIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((serviceId) => serviceId !== id)
        : [...prevSelected, id]
    );
  };

  // const handleConfirmBooking = () => {
  //   setShowModal(true);
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const svgMap = {
    61: banners?.length > 0 && banners[0]?.image,
    54: banners?.length > 0 && banners[1]?.image,
    58: banners?.length > 0 && banners[2]?.image,
    55: banners?.length > 0 && banners[3]?.image,
    59: banners?.length > 0 && banners[4]?.image,
    53: banners?.length > 0 && banners[5]?.image,
    56: banners?.length > 0 && banners[6]?.image,
    60: banners?.length > 0 && banners[7]?.image,
    57: banners?.length > 0 && banners[8]?.image,
  };

  return (
    <>
      <style jsx="true">{`
        .services-back {
          overflow-x: hidden;

          .services-top-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: relative;

            .services-image {
              width: 100%;
            }

            .our-services-container {
              position: absolute;
              top: 35%;
              right: 6%;
              width: 45%;
              display: flex;
              flex-direction: column;
            }

            .glassmorphic-card {
              padding: 2% 4%;
              backdrop-filter: blur(7px) saturate(200%);
              -webkit-backdrop-filter: blur(7px) saturate(200%);
              background-color: rgba(219, 219, 219, 0.58);
              border-radius: 12px;
              border: 1px solid rgba(255, 255, 255, 0.125);
            }

            .our-services-heading {
              margin-right: 6%;
              font-size: 3vw;
              font-weight: bold;
              text-align: end;
            }

            .our-services-line {
              width: 4%;
              padding: 0;
              margin: 0;
              display: none;
            }

            .our-services-para {
              font-size: 1.1vw;
              font-weight: 500;
              color: #444;
              text-align: justify;
            }
          }

          @media screen and (max-width: 768px) {
            .services-top-section {
              height: 150px;

              .services-image {
                width: 100%;
                height: 100%;
              }

              .our-services-container {
                right: 0;
                width: 100% !important;
                align-items: center !important;
              }

              .our-services-line {
                display: block;
                width: 5%;
                position: absolute;
                top: 35%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .our-services-para {
                width: 50% !important;
              }
            }
          }

          .separator-class {
            width: 100%;
            height: 1vh;
            background-color: #fff;
          }

          .services-bottom-section {
            background: url(${BACK});
            background-size: 300%;
            background-repeat: none;
            background-position: center;
          }

          .our-services-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 5%;

            .service-category-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              border-radius: 0.5rem;
              padding: 2.5rem;
              margin-bottom: 5rem;

              h4 {
                color: #121212;
                font-weight: bold;
              }

              .tabs-main-class {
                display: flex;
                color: #121212;
                border-radius: 5px 5px 0px 0px;

                &.focus {
                  background-color: #000;
                }
              }

              .services-list-class {
                color: #869672;
                background-color: #dae2d3;
                font-size: 1.2vw;
                text-transform: uppercase;
                border-color: #dae2d3;
                border-radius: 5px 5px 5px 5px;
                transition: all 0.5s ease-in-out;

                &:hover {
                  color: #869672;
                  background-color: #fff;
                  border-color: #fff;
                }

                &.active {
                  color: #869672;
                  background-color: #fff;
                  border-color: #fff;
                }
              }
            }

            @media screen and (max-width: 989px) {
              .service-category-class {
                .services-list-class {
                  font-size: 2vw;
                  transition: all 0.5s ease-in-out;
                }
              }
            }

            @media screen and (max-width: 768px) {
              .service-category-class {
                .services-list-class {
                  font-size: 1.8vw;
                  transition: all 0.5s ease-in-out;
                }
              }
            }

            @media screen and (max-width: 480px) {
              .service-category-class {
                width: 110%;

                .services-list-class {
                  font-size: 2vw;
                  transition: all 0.5s ease-in-out;
                }
              }
            }
          }
        }
      `}</style>
      <div className="services-back">
        <div className="services-top-section">
          <img
            src={svgMap[serviceID] || svgMap["1"]}
            alt=""
            className="services-image"
          />
          <div className="container">
            <div className="our-services-container">
              <div className="glassmorphic-card">
                <h1 className="our-services-heading text-white">
                  {service?.name}
                </h1>
                {service && <hr className="our-services-line" />}
                <p className="our-services-para">{service?.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="separator-class" /> */}
        <div className="services-bottom-section">
          <div className="container">
            <div className="our-services-content">
              <div className="service-category-class">
                <Tabs
                  defaultActiveKey={categoriesList[0]?.name}
                  id="justify-tab-example"
                  className="tabs-main-class"
                  variant="underline"
                  justify
                >
                  {categoriesList?.map((item, index) => (
                    <Tab
                      key={index}
                      eventKey={item.name}
                      title={item.name}
                      tabClassName="services-list-class"
                    >
                      {/* {selectedServiceIds.length > 0 && (
                        <div className="d-flex justify-content-end mt-3">
                          <button
                            onClick={handleConfirmBooking}
                            className="learn-more-btn px-3 py-3"
                          >
                            <i className="fa-solid fa-cart-shopping"></i>{" "}
                          </button>
                        </div>
                      )} */}
                      <ServicesComponent
                        subServices={item.sub_services}
                        selectedServiceIds={selectedServiceIds}
                        onSelectService={handleSelectService}
                      />
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {service && (
        <ConfirmBookingDetails
          show={showModal}
          onHide={handleCloseModal}
          selectedServiceIds={selectedServiceIds}
          service={service}
        />
      )}
    </>
  );
};

export default OurServices;
