import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./modelStyles.css";
import HierarchicalTable from "../Tables/HirarichalTables";
import { onDealsCancel } from "../../utils/onDealsCancel";

const ConfirmDealsModal = ({
  show,
  onHide,
  selectedDealIds,
  deals,
  setDeals,
  setSelectedDealIds,
}) => {
  const navigate = useNavigate();

  const [personCounts, setPersonCounts] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedDeals = useMemo(() => {
    return deals.filter((deal) => selectedDealIds.includes(deal.id));
  }, [deals, selectedDealIds]);

  const handlePersonCountChange = (id, value) => {
    setPersonCounts((prevCounts) => ({
      ...prevCounts,
      [id]: Math.max(1, (prevCounts[id] || 1) + value),
    }));
  };

  const selectedData = useMemo(() => {
    return selectedDeals.map((deal) => ({
      id: deal.id,
      name: deal.name,
      price: deal.price,
      discounted_price: deal.discounted_price,
      persons: personCounts[deal.id] || 1,
      isService: true,
      subRows: deal.included_items.map((item, index) => ({
        id: `${deal.id}-${index}`,
        name: item,
        isItem: true,
      })),
    }));
  }, [selectedDeals]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          const indent = row.depth * 20;
          return (
            <span style={{ paddingLeft: `${indent}px` }}>
              {row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  {row.isExpanded ? "▼" : "▶"}
                </span>
              ) : (
                " "
              )}
              {row.original.isService ? (
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {value}
                </span>
              ) : (
                value
              )}
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ row }) => {
          if (row.original.isService) {
            return (
              <div>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "red",
                    marginRight: "10px",
                  }}
                >
                  {row.original.price.toFixed(2)}
                </span>
                <span>{row.original.discounted_price.toFixed(2)}</span>
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: "Persons",
        accessor: "persons",
        Cell: ({ row }) => {
          if (row.original.isService) {
            return (
              <div>
                <button
                  className="learn-more-btn"
                  onClick={() => handlePersonCountChange(row.original.id, -1)}
                >
                  -
                </button>
                <span style={{ margin: "0 10px" }}>
                  {personCounts[row.original.id] || 1}
                </span>
                <button
                  className="learn-more-btn"
                  onClick={() => handlePersonCountChange(row.original.id, 1)}
                >
                  +
                </button>
              </div>
            );
          }
          return null;
        },
      },
    ],
    [personCounts]
  );

  const totalAmount = useMemo(() => {
    return selectedDeals.reduce(
      (total, deal) =>
        total + deal.discounted_price * (personCounts[deal.id] || 1),
      0
    );
  }, [selectedDeals, personCounts]);

  const handleCheckout = () => {
    onHide();
    navigate("/checkout", {
      state: {
        numPersons: personCounts,
        selectedData: selectedData,
        selectedDeals: selectedDeals.map((deal) => ({
          ...deal,
          numPersons: personCounts[deal.id] || 1,
        })),
      },
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Service Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HierarchicalTable selectedData={selectedData} columns={columns} />
        <div className="text-right">
          <h5>Total Amount: {totalAmount.toFixed(2)}</h5>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="more-detail-btn" onClick={onHide}>
          Close
        </Button>
        <Button
          className="more-detail-btn"
          onClick={() =>
            onDealsCancel(navigate, "/", onHide, setDeals, setSelectedDealIds)
          }
        >
          Cancel
        </Button>
        <Button className="learn-more-btn" onClick={handleCheckout}>
          Checkout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDealsModal;
