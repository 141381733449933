import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { object } from "yup";

export default (
  heading,
  description,
  onYes = () => {},
  onNo = () => {},
  noBtnText,
  yesBtnText
) => {
  toast((t) => (
    <span className="py-3">
      {heading && <p className="fs-6 fw-bold">{heading}</p>}
      {description && (
        <p className="fs-6" style={{ fontWeight: "600" }}>
          {description}
        </p>
      )}
      <Button
        className="learn-more-btn"
        onClick={() => {
          onYes();
          toast.dismiss(t.id);
        }}
      >
        {yesBtnText || "Yes"}
      </Button>
      <Button
        className="more-detail-btn ms-2"
        onClick={() => {
          onNo();
          toast.dismiss(t.id);
        }}
      >
        {noBtnText || "No"}
      </Button>
    </span>
  ));
};
