import React from 'react';

const AppointmentDetails = ({ appointmentDetails }) => {
  return (
    <div className="container mt-4">
    <div className="row">
      <div className="col-12 col-md-4 mb-3">
        <label className="fw-bold checkout-heading">Appointment ID:</label>
        <p>{appointmentDetails.booking_id}</p>
      </div>
      <div className="col-12 col-md-4 mb-3">
        <label className="fw-bold checkout-heading">Booking Date:</label>
        <p>
          {new Date(
            appointmentDetails.booking_date
          ).toLocaleString()}
        </p>
      </div>
      <div className="col-12 col-md-4 mb-3">
        <label className="fw-bold checkout-heading">Payment Status:</label>
        <p>{appointmentDetails.payment_status}</p>
      </div>
      <div className="col-12 col-md-4 mb-3">
        <label className="fw-bold checkout-heading">Booking Status:</label>
        <p>{appointmentDetails.booking_status}</p>
      </div>
      <div className="col-12 col-md-4 mb-3">
        <label className="fw-bold checkout-heading">Total Price:</label>
        <p>${appointmentDetails.total_price}</p>
      </div>
    </div>
  </div>
  );
};

export default AppointmentDetails;
