import { useContext, useEffect } from "react";
import { DataContext } from "../App";
import Hero from "../components/LandingPageComponents/Hero";
import WelcomeSection from "../components/LandingPageComponents/Welcome";
import OurServices from "../components/LandingPageComponents/OurServices";
import Health from "../components/LandingPageComponents/health";
import OurExperts from "../components/LandingPageComponents/OurExperts";
import Testimonials from "../components/LandingPageComponents/Testimonials";
import ContactUS from "../components/LandingPageComponents/ContactUs";
import WhatsAppButton from "../components/LandingPageComponents/WhatsappIcon";
import AboutUs from "./aboutUs/AboutUs";
import LearnMore from "./learnMore/LearnMore";
// import AboutUs from "../components/LandingPageComponents/aboutUs";

export default function LandingPage() {
  const { data } = useContext(DataContext);

  const {
    hero_section,
    welcome_section,
    our_services,
    health_section,
    expert_section,
    contact_us_page,
    // aboutus_section,
    feedback_page,
  } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero data={hero_section} />
      <WelcomeSection data={welcome_section} />
      <OurServices data={our_services} />
      <Health data={health_section} />
      <OurExperts data={expert_section} />
      <Testimonials data={feedback_page} />
      <ContactUS content={contact_us_page} />
      <WhatsAppButton />
      {/* <AboutUs data={aboutus_section} /> */}
    </>
  );
}
