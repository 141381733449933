import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast.jsx";
import FeedbackForm from "./Components/FeedbackForm";
import IMG from "../../assets/80.png";

const Feedback = ({ content }) => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState();

  const onSubmit = async (values) => {
    if (process.env.REACT_APP_RECAPTCHA_ENABLER === "true") {
      if (!captchaValue) {
        toast.error("Please verify reCAPTCHA");
        return;
      }
    }

    setIsLoading(true);

    let payload = {
      ...values,
    };

    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("location", payload.location);
    formData.append("message", payload.message);
    formData.append("isApproved", false);

    if (payload.file.length > 0) formData.append("file", payload.file[0]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}testimonials/feedback/`,
        formData
      );
      if (response.data.error) {
        toast.error("Error!");
        return;
      }
      window.scrollTo(0, 0);
      toast.success("Feedback Submitted");
      setIsFormSubmitted(true);
    } catch (error) {
      toast.error("Unable to send feedback, Please try again later", {
        id: "send-feedback-fail",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .feedback-back {
          background-image: url(${IMG});
          background-size: cover;
          background-position: center;
          height: 100%;

          .feedback-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 4rem;

            .feedback-heading {
              color: #68755c;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .feedback-line {
              width: 5%;
            }

            .feedback-para {
              font-size: 1vw;
              font-weight: 500;
            }

            .feedback-form {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 25px 0px 50px 0px;

              .submitted-text {
                color: #869672;
                font-size: 1.5vw;
                font-weight: bold;
              }
            }

            @media screen and (max-width: 768px) {
              .feedback-para {
                font-size: 3vw;
                margin-bottom: 30px;
              }

              .feedback-line {
                width: 10%;
              }

              .feedback-form {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 50px;

                .submitted-text {
                  font-size: 5vw;
                  margin-bottom: 25px;
                }

                .feedback-image-class {
                  .feedback-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-bottom: 10%;
                  }
                }
              }
            }
          }
        }
      `}</style>

      <div className="feedback-back">
        <div className="container">
          <div className="feedback-content">
            <h1 className="feedback-heading">{content[0].description}</h1>
            <hr className="feedback-line" />
            <p className="feedback-para col-md-10">{content[1].description}</p>
            <div className="feedback-form">
              {!isFormSubmitted ? (
                <FeedbackForm
                  setCaptchaValue={setCaptchaValue}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  content={content[3].description}
                />
              ) : (
                <div className="col-8">
                  <p className="submitted-text">{content[2].description}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Feedback;
