import React from "react";
import GradientButton from "../../utils/button";
export default function WelcomeSection({ data }) {
  return (
    <>
      <style jsx="true">{`
        .welcome-heading {
          color: #121212;
          margin-bottom: 20px;
        }

        .learn-more-btn {
          font-size: 1vw;
          font-weight: 500;
          color: #ffffff;
          background: linear-gradient(to top right, #68755c, #869672);
          border: none;
          border-radius: 3px;
          text-decoration: none;
          text-transform: uppercase;
          padding: 1% 5%;

          &:hover {
            color: #121212;
            transition: 0.3s all ease-in-out;
          }
        }
      `}</style>

      <div className="container py-5">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-md-4 d-flex justify-content-center mb-3">
            <img src={data[2].image} alt="Welcome" className="img-fluid" />
          </div>
          <div className="col-md-7 p-md-3 d-flex justify-content-between align-items-center">
            <div className="text-center text-md-start">
              <h1 className="welcome-heading">{data[0].description}</h1>
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: data[1].description }}
              />
              {/* <p className="lead">{data[1].description}</p> */}
              <div className="col-md-8">
                <a className="learn-more-btn" href="/about-us">
                  {data[3].description}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
