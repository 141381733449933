import React from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const SubCategoryModal = ({ show, onHide, subCategory }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Sub Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body className='striped-background'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {subCategory.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
export default SubCategoryModal;


