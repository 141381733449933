import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";

const ServicesComponent = ({subServices,selectedServiceIds, onSelectService}) => {
  const [numCards, setNumCards] = useState(subServices.length);

  // Check the number of cards and update the state
  useEffect(() => {
    setNumCards(subServices.length);
  }, [subServices]);

  return (
    <>
      <style jsx="true">{`
        .tab-component-back {
          display: flex;
          flex-direction: row;
          justify-content: center; /* Default is center */
          border-radius: 15px;
          padding-bottom: 30px;
          padding-top: 30px;
        }

        .tab-component-class {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: "space-between";
        }

        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }
      `}</style>

      <div className="tab-component-back">
        <div className="tab-component-class" style={{justifyContent: numCards <= 2 ? "center" : "space-between"}}>
          {subServices &&
            subServices.map((item) => {
              const {
                id,
                name,
                price,
                currency,
                description,
                image,
                children,
                subservice_category,
              } = item;
              const hasChildren = children && children.length > 0;
              const hasSubCategories =
                subservice_category && subservice_category.length > 0;
              return (
                <ServiceCard
                  key={id}
                  id={id}
                  name={name}
                  description={description}
                  price={currency + " " + price}
                  image={image}
                  hasChildren={hasChildren}
                  subCategories={subservice_category}
                  hasSubCategories={hasSubCategories}
                  isSelected={selectedServiceIds.includes(id)}
                  onSelectService={onSelectService}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ServicesComponent;
