import React, { useState, useEffect } from "react";
import axios from "axios";
import ExpertsCarousel from "../Carousel/ExpertsCarousel/ExpertsCarousel";
import toast from "react-hot-toast";

export default function OurExperts({ data }) {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}hiring/our_team/`
        );

        setTeamData(responseData?.data);
      } catch (error) {
        toast.error("Unable to load our team content", { id: "our-team" });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <style jsx="true">{`
        .our-experts-heading {
          color: #68755c;
        }

        .our-experts-heading-end {
          color: #121212 !important;
        }

        .our-experts-sub-heading {
          color: #121212;
          font-size: 1vw;
        }

        @media screen and (max-width: 989px) {
          .our-experts-sub-heading {
            color: #121212;
            font-size: 1.5vw;
          }
        }

        @media screen and (max-width: 800px) {
          .our-experts-sub-heading {
            color: #121212;
            font-size: 2vw;
          }
        }

        @media screen and (max-width: 480px) {
          .our-experts-sub-heading {
            color: #121212;
            font-size: 3vw;
          }
        }
      `}</style>

      <section className="py-5">
        <div className="container">
          <div className="w-auto mx-auto" style={{ maxWidth: "fit-content" }}>
            <h2
              className="text-center m-4 mb-3 fw-bold our-experts-heading"
              dangerouslySetInnerHTML={{ __html: data[0].description }}
            ></h2>
            <hr></hr>
          </div>
          <p className="col-md-10 mx-auto text-center mb-md-5 mb-3 fw-500 our-experts-sub-heading">
            {data[4].description}
          </p>
          {teamData && <ExpertsCarousel items={teamData} />}
        </div>
      </section>
    </>
  );
}
