// components/WhatsAppButton.js
import React from 'react';

const WhatsAppButton = () => {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const whatsappLink = `https://wa.me/${whatsappNumber}`;

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        padding: '8px 15px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
        textAlign: 'center',
        fontSize: '24px',
        zIndex: '1000',
      }}
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};

export default WhatsAppButton;
