import React, { useMemo, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BACKGROUND from "../../assets/80.png";
import HierarchicalTable from "../../components/Tables/HirarichalTables";
import AppointmentDetails from "./AppointmentDetails";
import OTPModal from "../../components/Modal/OtpModel";
import ConfirmModal from "../../components/Modal/confirmModel";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const AppointmentsPage = () => {
  const [appointmentId, setAppointmentId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isLookupLoading, setIsLookupLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [error, setError] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [service, setService] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState("");
  const [showLookupForm, setShowLookupForm] = useState(true);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          const indent = row.depth * 20;
          return (
            <span style={{ paddingLeft: `${indent}px` }}>
              {row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  {row.isExpanded ? "▼" : "▶"}
                </span>
              ) : (
                " "
              )}
              {row.original.isService ? (
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {value}
                </span>
              ) : row.original.isCategory ? (
                <span style={{ fontWeight: "bold" }}>{value}</span>
              ) : (
                value
              )}
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Persons",
        accessor: "no_of_persons",
        Cell: ({ row, value }) =>
          !row.original.isCategory &&
          !row.original.isService && (
            <div className="d-flex align-items-center justify-content-around">
              <span>{value}</span>
            </div>
          ),
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: ({ row }) => {
          if (!row.original.isCategory && !row.original.isService) {
            const price = row.original.price;
            const tnumPersons = row.original.no_of_persons;
            return `${(price * tnumPersons).toFixed(2)}`;
          }
          return null;
        },
      },
    ],
    []
  );

  const selectedData = useMemo(() => {
    if (!service.services) return [];

    const mapSubServices = (subServices) =>
      subServices.map((subService) => ({
        ...subService,
        isSubService: true,
        subRows: subService.subservice_category
          ? mapSubServices(subService.subservice_category)
          : [],
      }));

    return service.services.map((serviceItem) => ({
      id: serviceItem.id,
      name: serviceItem.name,
      description: serviceItem.description,
      isService: true,
      subRows: serviceItem.categories.map((category) => ({
        id: category.id,
        name: category.name,
        description: category.description,
        isCategory: true,
        subRows: mapSubServices(category.sub_services),
      })),
    }));
  }, [service]);

  const handleAppointmentLookup = async () => {
    const trimmedAppointmentId = appointmentId.trim();
    const trimmedContactNumber = contactNumber.trim();
    if (process.env.REACT_APP_RECAPTCHA_ENABLER === "true") {
      if (!captchaValue) {
        toast.error("Are you a human?");
        return;
      }
    }
    setIsLookupLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}booking/get-booking/`,
        {
          params: {
            booking_id: trimmedAppointmentId,
            contact_number: trimmedContactNumber,
          },
        }
      );
      setAppointmentDetails(response.data[0]);
      setService(response.data[0].selected_items);
      setError(null);
      setShowLookupForm(false);
    } catch (error) {
      setAppointmentDetails(null);
      toast.error("Appointment not found. Please check your details.");
    } finally {
      setIsLookupLoading(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSendOTP = async () => {
    setIsCancelLoading(true);
    const trimmedAppointmentId = appointmentId.trim();
    const trimmedContactNumber = contactNumber.trim();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/booking/send-otp/`,
        {
          booking_id: trimmedAppointmentId,
          contact_number: trimmedContactNumber,
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(
          "OTP has been sent to your registered email successfully."
        );
        setShowConfirmModal(false);
        setShowOTPModal(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      toast.error("Failed to send OTP.");
    } finally {
      setIsCancelLoading(false);
    }
  };

  const handleCancelAppointment = async () => {
    setIsOTPLoading(true);
    const trimmedAppointmentId = appointmentId.trim();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/booking/cancel-booking/`,
        {
          booking_id: trimmedAppointmentId,
          otp,
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Booking canceled successfully.");
        setShowOTPModal(false);
        navigate("/");
      } else {
        toast.error("Failed to cancel booking.");
      }
    } catch (error) {
      toast.error("Failed to cancel booking.");
    } finally {
      setIsOTPLoading(false);
    }
  };

  return (
    <section
      className="py-md-5 mt-md-5 py-3 mt-0"
      style={{
        backgroundImage: `url(${BACKGROUND})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "80vh",
      }}
    >
      <ToastContainer />
      <div className="container">
        {showLookupForm && (
          <>
            <h2 className="text-center my-4 checkout-heading">Appointments</h2>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div
                className="d-flex flex-wrap justify-content-center align-items-end mb-3"
                style={{ gap: "8px" }}
              >
                <div className="form-group" style={{ minWidth: "400px" }}>
                  <label htmlFor="appointmentId" className="mb-2">
                    Appointment ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="appointmentId"
                    value={appointmentId}
                    onChange={(e) => setAppointmentId(e.target.value)}
                  />
                </div>
                <div className="form-group" style={{ minWidth: "400px" }}>
                  <label htmlFor="contactNumber" className="mb-2">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactNumber"
                    value={contactNumber}
                    placeholder="(e.g +92-xxx-xxxxxxx, No spaces or dashesh)"
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </div>
              </div>
              {error && <p className="text-danger mt-md-4">{error}</p>}

              {process.env.REACT_APP_RECAPTCHA_ENABLER === "true" ? (
                <Row className="field-col mt-md-4">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </Row>
              ) : null}
              <button
                className="btn btn-primary learn-more-btn mt-md-3 mt-2"
                setCaptchaValue={setCaptchaValue}
                onClick={handleAppointmentLookup}
              >
                {isLookupLoading ? (
                  <span
                    className="spinner-border spinner-border-sm mx-md-5 mx-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Lookup Appointment"
                )}
              </button>
            </div>
          </>
        )}
        <div className="d-flex flex-column align-items-center justify-content-center mt-md-5 mt-3">
          {appointmentDetails && !showLookupForm && (
            <>
              <h3 className="checkout-heading mb-3">Appointment Details</h3>
              {appointmentDetails.booking_status === "CANCELLED" && (
                <div className="alert alert-warning fw-bold" role="alert">
                  This appointment has been canceled. Contact support for more
                  information
                </div>
              )}
              <HierarchicalTable
                selectedData={selectedData}
                columns={columns}
              />
              <AppointmentDetails appointmentDetails={appointmentDetails} />
              <div className="d-flex justify-content-end w-100">
                {!showLookupForm && (
                  <button
                    className="btn btn-primary learn-more-btn py-2 px-3 me-3"
                    onClick={() => setShowLookupForm(true)}
                  >
                    Lookup Again
                  </button>
                )}
                {appointmentDetails.booking_status !== "CANCELLED" && (
                  <button
                    className="btn btn-danger py-2 px-3"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    Cancel Appointment
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <OTPModal
        show={showOTPModal}
        onHide={() => setShowOTPModal(false)}
        otp={otp}
        setOtp={setOtp}
        handleVerifyOTP={handleCancelAppointment}
        isOTPLoading={isOTPLoading}
      />
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleSendOTP}
        isCancelLoading={isCancelLoading}
      />
    </section>
  );
};

export default AppointmentsPage;
