import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

export const onDealsCancel = (navigate, navigateTo,onHide,setDeals,setSelectedDealIds) => {
  toast((t) => (
    <span className="py-3">
      <p className="fs-6 fw-bold">Are you sure you want to cancel it?</p>
      <p className="fs-6" style={{ fontWeight: "600" }}>
        because if you cancel it, all your selected items will be cleared.
      </p>
      <Button
        className="learn-more-btn"
        onClick={() => {
          localStorage.clear();
          navigate(navigateTo);
          toast.dismiss(t.id);
          onHide()
          setDeals([])
          setSelectedDealIds([])
        }}
      >
        Yes
      </Button>
      <Button
        className="more-detail-btn ms-2"
        onClick={() => toast.dismiss(t.id)}
      >
        Cancal
      </Button>
    </span>
  ));
};
