import React, { useRef } from "react";
import "./ExpertsCarousel.scss";
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const ExpertsCarousel = ({ items }) => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <FaArrowLeftLong color="#68755c" />,
    nextArrow: <FaArrowRightLong color="#68755c" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (currentSlide, nextSlide) => {
      if (nextSlide === 0) {
        sliderRef.current.slickPrev();
      }
    },
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings} className="carousel-slider-class-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="d-flex flex-column justify-content-center align-items-center text-center"
          >
            <div className="our-experts-images">
              <img
                src={item?.image}
                alt={item?.name}
                className="our-experts-image"
              />
            </div>
            <h6 className="our-experts-name">{item?.name}</h6>
            <p className="our-experts-designation">{item?.designation}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ExpertsCarousel;
