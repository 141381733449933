import React from "react";
import "../../App.css";
import GradientButton from "../../utils/button";

const Hero = ({ data }) => {
  return (
    <>
      <style jsx="true">{`
        .heading-color {
          color: #68755c !important;
        }

        .heading-end {
          font-weight: 500 !important;
        }

        .lead {
          font-size: 1.2vw;
          text-align: justify;
        }

        .learn-more-btn {
          font-size: 1vw;
          font-weight: 500;
          color: #ffffff;
          background: linear-gradient(to top right, #68755c, #869672);
          border: none;
          border-radius: 3px;
          text-decoration: none;
          text-transform: uppercase;
          padding: 1% 5%;
          margin-right: 20px;

          &:hover {
            color: #121212;
            transition: 0.3s all ease-in-out;
          }
        }

        .more-detail-btn {
          font-size: 1vw;
          font-weight: 500;
          color: #ffffff;
          background: linear-gradient(to bottom right, #000101, #323131);
          border: none;
          border-radius: 3px;
          text-decoration: none;
          text-transform: uppercase;
          padding: 1% 5%;
          margin-right: 20px;

          &:hover {
            color: #ffffff;
            background: linear-gradient(to bottom right, #000101, #000101);
            transition: 0.3s all ease-in-out;
          }
        }

        @media screen and (max-width: 768px) {
          .hero-image {
            width: 75%;
          }

          .display-4 {
            font-size: 5vw;
          }

          .lead {
            font-size: 2.5vw;
          }

          .learn-more-btn {
            font-size: 2vw !important;
            margin-right: 10px;
          }

          .more-detail-btn {
            font-size: 2vw !important;
          }
        }

        @media screen and (max-width: 480px) {
          .hero-image {
            width: 50%;
          }

          .display-4 {
            font-size: 5vw;
          }

          .lead {
            font-size: 3vw;
          }

          .learn-more-btn {
            font-size: 2vw !important;
            margin-right: 10px;
          }

          .more-detail-btn {
            font-size: 2vw !important;
          }
        }
      `}</style>

      <div
        className="hero-section mt-md-0 py-5"
        style={{
          backgroundImage: `url(${data[5].image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <h1
                className="display-4"
                dangerouslySetInnerHTML={{ __html: data[0].description }}
              />
              <p className="lead text-brand-gray">{data[4].description}</p>
              <div className="d-flex">
                <a className="learn-more-btn" href="/about-us">
                  {data[1].description}
                </a>
                <a className="more-detail-btn" href="/learn-more">
                  {data[2].description}
                </a>
              </div>
            </div>
            <div className="col-md-6 d-md-flex d-none justify-content-end">
              <div className="p-5 pe-0">
                <img
                  src={data[3].image}
                  alt={data[3].extras}
                  className="img-fluid hero-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
