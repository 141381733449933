import React, { useState, useEffect } from "react";
import IMG from "../../assets/4.png";
import DealsComponent from "./Components/DealsComponent";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast.jsx";
import axios from "axios";

const Deals = ({deals,setDeals,selectedDealIds, setSelectedDealIds}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const fetchDeals = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}policies/admin/fetch_deals/`
      );
      setDeals(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch the policies");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDeals();
    window.scrollTo(0, 0);
  }, []);
  const handleConfirmBooking = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSelectDeal = (id) => {
    setSelectedDealIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((serviceId) => serviceId !== id)
        : [...prevSelected, id]
    );
  };
  useEffect(() => {
    if(selectedDealIds?.length > 0){
      localStorage.removeItem("selectedServices");
      localStorage.removeItem("numPersons");
      localStorage.removeItem("selectedServiceIds");
    }
  }, [selectedDealIds]);
  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .deals-back {
          background-image: url(${IMG});
          background-size: cover;
          background-position: center;
          height: 100%;

          .deals-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 4rem;

            .deals-heading {
              color: #68755c;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .deals-line {
              width: 5%;
            }

            .deals-para {
              font-size: 1vw;
              font-weight: 500;
            }

            .contact-us-form {
              width: 88%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 50px;

              .submitted-text {
                color: #869672;
                font-size: 1.6vw;
                font-weight: bold;
              }

              .contact-us-image-class {
                .contact-us-img {
                  width: 80%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            @media screen and (max-width: 768px) {
              .deals-para {
                font-size: 3vw;
                margin-bottom: 30px;
              }

              .deals-line {
                width: 10%;
              }

              .contact-us-form {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 50px;

                .submitted-text {
                  font-size: 5vw;
                  margin-bottom: 25px;
                }

                .contact-us-image-class {
                  .contact-us-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      `}</style>

      <div className="deals-back">
        <div className="container">
          <div className="deals-content">
            <h1 className="deals-heading">Deals</h1>
            <hr className="deals-line" />
            <p className="deals-para col-md-10">
              Unlock Exclusive Savings and Luxurious Treatments at Lacigal -
              Your Destination for Irresistible Salon Deals and Special Offers
            </p>
            <div className="contact-us-form">
              <DealsComponent
                deals={deals}
                selectedDealIds={selectedDealIds}
                onSelectDeal={handleSelectDeal}
              />
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Deals;
