import React from "react";
import { Modal, Button } from "react-bootstrap";

const SuccessModal = ({ show, handleClose, bookingId }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="checkout-heading">
          Booking Confirmed
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="striped-background">
        <p className="text-center">
          Your booking has been confirmed. One of our team member will contact
          you for time allocation after payment is confirmed
        </p>
        <p>
          <span className="fw-bold">Booking ID: </span>
          {bookingId}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="learn-more-btn"
          variant="primary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
