import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import AppRoutes from "./routes/appRoutes";
import Navbar from "./components/LandingPageComponents/Navbar";
import LandingPage from "./pages/LandingPage";
import OurServices from "./pages/ourServicesPage/ourServices";
import Deals from "./pages/dealsPage/deals";
import OurExperts from "./pages/ourExpertsPage/ourExperts";
import ContactUs from "./pages/contactUsPage/contactUs";
import Hiring from "./pages/hiringPage/hiring";
import Feedback from "./pages/feedbackPage/feedback";
import PrivacyPolicy from "./pages/privacyPolicyPage/privacyPolicy";
import Footer from "./components/LandingPageComponents/footer";
import CheckoutPage from "./pages/CheckoutPage";
import AppointmentsPage from "./pages/appointmentPage/Appointment";
import TermsAndPolicy from "./pages/termsPolicyPage/TermsAndPolicy";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ModalContextProvider } from "./context/Modal.Context";
import AboutUs from "./pages/aboutUs/AboutUs";
import LearnMore from "./pages/learnMore/LearnMore";
import { UpdateCartContextProvider } from "./context/UpdateCartContext";

export const DataContext = createContext();

function App() {
  const [data, setData] = useState(null);
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [selectedDealIds, setSelectedDealIds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/data/datafile.json`);
      const json = await response.json();
      setData(json);
    }
    fetchData();
    const fetchDataSocial = async () => {
      try {
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/testimonials/social-accounts`
        );
        setSocialAccounts(apiResponse.data);
      } catch (error) {
        toast.error("Unable to load social accounts", {
          id: "social-accounts",
        });
      }
    };

    fetchDataSocial();
  }, []);

  if (!data) {
    return (
      <>
        <style jsx="true">{`
          .loader-class {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
        <div className="loader-class">
          <Spinner
            variant="dark"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      </>
    );
  }

  return (
    <Router>
      <ModalContextProvider>
        <UpdateCartContextProvider>
          <Navbar
            brand={data?.brand}
            navLinks={data?.nav_links}
            socialAccounts={socialAccounts}
            brandDetails={data?.footer_brand_details}
            deals={deals}
            selectedDealIds={selectedDealIds}
            setSelectedDealIds={setSelectedDealIds}
            setDeals={setDeals}
          />
          <DataContext.Provider value={{ data }}>
            <Routes>
              <Route path={AppRoutes.HOME} element={<LandingPage />} />
              <Route
                path={AppRoutes.CHECKOUT}
                element={
                  <CheckoutPage
                    setSelectedDealIds={setSelectedDealIds}
                    setDeals={setDeals}
                  />
                }
              />
              <Route
                path={AppRoutes.SERVICES}
                element={<OurServices content={data?.service_headers} />}
              />
              <Route
                path={AppRoutes.appointments}
                element={<AppointmentsPage />}
              />
              <Route
                path={AppRoutes.DEALS}
                element={
                  <Deals
                    content={data?.hiring_page}
                    deals={deals}
                    setDeals={setDeals}
                    selectedDealIds={selectedDealIds}
                    setSelectedDealIds={setSelectedDealIds}
                  />
                }
              />
              <Route
                path={AppRoutes.OUR_EXPERTS}
                element={<OurExperts content={data?.our_team_page} />}
              />
              <Route
                path={AppRoutes.CONTACT_US}
                element={<ContactUs content={data?.contact_us_page} />}
              />
              <Route
                path={AppRoutes.CAREERS}
                element={<Hiring content={data?.hiring_page} />}
              />
              <Route
                path={AppRoutes.FEEDBACK}
                element={<Feedback content={data?.feedback_page} />}
              />
              <Route
                path={AppRoutes.PRIVACY_POLICY}
                element={<PrivacyPolicy content={data?.feedback_page} />}
              />
              <Route
                path={AppRoutes.TERMS_POLICY}
                element={<TermsAndPolicy content={data?.feedback_page} />}
              />
              <Route
                path={AppRoutes.ABOUT_US}
                element={<AboutUs content={data?.about_us_page} />}
              />
              <Route
                path={AppRoutes.LEARN_MORE}
                element={<LearnMore content={data?.learn_more_page} />}
              />
            </Routes>
          </DataContext.Provider>
        </UpdateCartContextProvider>
      </ModalContextProvider>
      <Footer
        brandDetails={data?.footer_brand_details}
        aboutUs={data?.footer_about_us}
        services={data?.footer_services}
        socialAccounts={socialAccounts}
      />
      <Toaster />
    </Router>
  );
}

export default App;
