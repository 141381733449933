import React, { useState, useEffect } from "react";
import axios from "axios";
import ExpertCardOne from "./Components/expertCardOne";
import ExpertCardTwo from "./Components/expertCardTwo";
import IMG from "../../assets/2s.png";
import OURTEAM from "../../assets/our-team.png";
import { toast } from "react-toastify";

const OurExperts = ({ content }) => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}hiring/our_team/`
        );

        setTeamData(responseData?.data);
      } catch (error) {
        toast.error("Unable to load content", { id: "load-our-team" });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .our-team-back {
          background-image: url(${IMG});
          background-size: cover;
          background-position: center;
          height: 100%;

          .contact-us-heading {
            color: #68755c;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .contact-us-line {
            width: 15%;
          }

          .contact-us-para {
            font-size: 1vw;
            font-weight: 500;
          }

          .image-column {
            .our-team-img {
              width: 80%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        @media screen and (max-width: 768px) {
          .our-team-back {
            .contact-us-heading {
              margin-top: 25px;
            }

            .contact-us-para {
              font-size: 3vw;
              margin-bottom: 30px;
            }

            .contact-us-line {
              width: 10%;
            }

            .image-column {
              .our-team-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .team-cards-section {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 10% 0%;

          .meet-team-class {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        .team-section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          margin-top: 15px;
        }
      `}</style>

      <div className="our-team-back">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-5">
              <h1 className="contact-us-heading">{content[0].description}</h1>
              <hr className="contact-us-line" />
              <p className="contact-us-para">{content[1].description}</p>
            </div>
            <div className="col-md-7 d-md-flex d-none justify-content-end">
              <div className="p-5 pe-0">
                <img src={OURTEAM} alt="" className="img-fluid hero-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="team-cards-section">
          <div className="d-flex flex-column col-12 justify-content-center align-items-center">
            <div className="col-12 meet-team-class">
              {teamData &&
                teamData.map((member, index) => {
                  return member?.id % 2 === 0 ? (
                    <ExpertCardOne
                      key={index}
                      name={member?.name}
                      designation={member?.designation}
                      image={member?.image}
                    />
                  ) : (
                    <ExpertCardTwo
                      key={index}
                      name={member?.name}
                      designation={member?.designation}
                      image={member?.image}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurExperts;
