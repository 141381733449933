import React from "react";

const ExpertCardOne = ({ name, designation, image }) => {
  return (
    <>
      <style jsx="true">{`
        .card-one-background-class {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 25px 16px 50px 16px;

          .card-image-class {
            width: 325px;
            height: 325px;
            border-radius: 50px 0px;
            overflow: hidden;
            margin-bottom: 20px;
            box-shadow: -5px -5px 5px #d1d1d1;

            .card-team-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .card-info-class {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .member-name {
              color: #121212;
              font-size: 1.5vw;
              font-weight: 600;
            }

            .member-designation {
              color: #121212;
              font-size: 0.9vw;
              font-weight: 500;
            }
          }

          @media screen and (max-width: 768px) {
            .card-image-class {
              width: 300px;
              height: 300px;
              border-radius: 50px 0px;
              overflow: hidden;
              margin-bottom: 20px;
              box-shadow: -5px -5px 5px #d1d1d1;

              .card-team-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .card-info-class {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .member-name {
                color: #121212;
                font-size: 5vw;
                font-weight: 600;
              }

              .member-designation {
                color: #121212;
                font-size: 3.5vw;
                font-weight: 500;
              }
            }
          }

          &:hover {
            .card-info-class {
              .member-name {
                color: #68755c;
                transition: all 0.2s ease-in;
              }
            }
          }
        }
      `}</style>

      <div className="card-one-background-class">
        <div className="card-image-class">
          <img src={image} alt="" className="card-team-image" />
        </div>
        <div className="card-info-class">
          <h5 className="member-name">{name}</h5>
          <h6 className="member-designation">{designation}</h6>
        </div>
      </div>
    </>
  );
};

export default ExpertCardOne;
