import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import PolicyItem from "./PolicyItem";
import BACK from "../../assets/services-bottom-back8.jpg";

const TermsAndPolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}policies/policy/`
        );
        setPolicies(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Unable to load policies", { id: "policies" });
        setLoading(false);
      }
    };

    fetchPolicies();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <style jsx="true">{`
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }

        .privacy-policy-back {
          background-image: url(${BACK});
          background-size: 300%;
          background-position: center;
          background-repeat: no-repeat;
          color: #fff;
          overflow-x: hidden;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          position: relative;
        }

        .policy-content {
          margin-top: 10vh;
          padding: 2rem;
          border-radius: 15px;
        }

        .privacy-policy-heading {
          font-size: 3vw;
          font-weight: bold;
          text-align: center;
          color: #68755c;
        }

        .privacy-policy-line {
          width: 50%;
          margin: 1rem auto;
          border: 1px solid #fff;
        }

        .privacy-policy-para {
          font-size: 1.1vw;
          font-weight: 500;
          color: black;
          text-align: center;
        }

        .spinner-border {
          color: #fff;
        }

        @media screen and (max-width: 768px) {
          .privacy-policy-heading {
            font-size: 6vw;
          }

          .privacy-policy-para {
            font-size: 3vw;
          }
        }
      `}</style>

      <div className="privacy-policy-back">
        <div className="policy-content container">
          <h1 className="privacy-policy-heading">Terms & Policy</h1>
          <hr className="privacy-policy-line" />
          <p className="privacy-policy-para">
            Ensuring Transparency and Security: Our Commitment to Clear and Fair
            Policies at Lacigal - Your Trusted Partner in Beauty and Wellness.
          </p>
        </div>
        <div className="policy-bottom-section container">
          <div className="privacy-policy-content py-5 text-center">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              policies.map((policy) => (
                <PolicyItem
                  key={policy.id}
                  title={policy.title}
                  content={policy.content}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndPolicy;
