import React from "react";
import DealsCard from "./DealsCard";

const DealsComponent = ({deals ,selectedDealIds,onSelectDeal}) => {
  return (
    <>
      <style jsx="true">{`
        .deals-card-back {
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-radius: 15px 15px 15px 15px;
          padding-bottom: 30px;
          padding-top: 30px;

          .deals-card-sub-back {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          @media screen and (max-width: 1400px) {
            .deals-card-sub-back {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }

          @media screen and (max-width: 768px) {
            .deals-card-sub-back {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }

          @media screen and (max-width: 480px) {
            .deals-card-sub-back {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }
        }

        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 50px;
        }
      `}</style>

      <div className="deals-card-back col-md-12">
        <div className="deals-card-sub-back">
          {deals.map((item, key) => (
            <DealsCard
            id={item.id}
            key={item.id}
              name={item.name}
              services={item.included_items}
              price={item.price}
              isSelected={selectedDealIds.includes(item.id)}
              onSelectDeal={onSelectDeal}
              discounted_price={item.discounted_price}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DealsComponent;
