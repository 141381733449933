import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import AppRoutes from "../../routes/appRoutes";
import { ModalContext } from "../../context/Modal.Context";
import ConfirmDealsModal from "../Modal/ConfirmDeals";
import { UpdateCartContext } from "../../context/UpdateCartContext";
import toast from "react-hot-toast";

const Navbar = ({
  brand,
  navLinks,
  socialAccounts,
  brandDetails,
  deals,
  selectedDealIds,
  setDeals,
  setSelectedDealIds,
}) => {
  const location = useLocation();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
  const [apiData, setApiData] = useState();
  const [selectedServicesIds, setSelectedServiceIds] = useState();
  const { setShowModal } = useContext(ModalContext);
  const { updateCart, setUpdateCart } = useContext(UpdateCartContext);
  const [showModalDeals, setShowModalDeals] = useState(false);
  const handleCloseModal = () => {
    setShowModalDeals(false);
  };

  useEffect(() => {
    const servicesSelected = selectedServicesIds?.length > 0;
    const dealsSelected = selectedDealIds?.length > 0;
    const currentPath = location.pathname;
  
    // Define paths for Deals and Services pages
    const dealsPath = '/deals';
    const servicesPath = '/services';
  
    // Clear storage only if conditions are met
    if (
      (currentPath === dealsPath && servicesSelected) ||
      (currentPath === servicesPath && dealsSelected)
    ) {
      localStorage.clear();
      setUpdateCart(!updateCart);
      setDeals([]);
      setSelectedDealIds([]);
    }
  }, [location, selectedServicesIds, selectedDealIds]);

  const toggleSearch = () => {
    setIsSearchVisible((prevState) => !prevState);
  };

  const handleNavbarTogglerClick = () => {
    setIsNavbarCollapsed((prevState) => !prevState);
  };

  useEffect(() => {
    const services = JSON.parse(localStorage.getItem("selectedServiceIds"));
    setSelectedServiceIds(services ?? []);
  }, [updateCart]);
  // useEffect(() => {
  //   const savedDeals = localStorage.getItem("deals");
  //   const savedSelectedDealIds = localStorage.getItem("selectedDealIds");
  //   if (savedDeals) setDeals(JSON.parse(savedDeals));
  //   console.log(JSON.parse(savedDeals),"Deals in navbar")
  //   if (savedSelectedDealIds) setSelectedDealIds(JSON.parse(savedSelectedDealIds));
  // },[]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}service/services/`
        );
        setApiData(response.data?.services);
      } catch (error) {
        toast.error("Unable to load services", { id: "services" });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <style jsx="true">{`
        .navbar-top-class {
          background-color: white;
          position: fixed;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 1000;
        }
        .top-social-icon {
          position: absolute;
          top: -89px;
          left: 0%;
          width: 100%;
        }
        @media only screen and (max-width: 990px) {
          .nav-items-align-right {
            justify-content: end !important;
          }

          .cart-icon {
            margin-left: 10px !important;
          }
        }

        .navbar-nav {
          display: flex;
          justify-content: center;
          align-items: center;

          .nav-item {
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px 0px 10px;

            .nav-link {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              border-top: 3px solid #121212;
              border-bottom: 3px solid #121212;
              font-weight: 600;
              text-transform: uppercase;

              &:hover {
                color: #f3c2af;
                cursor: pointer;
                border-bottom: 3px solid #f3c2af;
              }

              &.active {
                color: #f3c2af;
                border-bottom: 3px solid #f3c2af;
              }
            }

            /* Dropdown menu styles */
            .dropdown-menu {
              border: none;
              border-radius: 0;
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Add box shadow for depth */
            }

            /* Dropdown item styles */
            .dropdown-item {
              color: #333;
              font-size: 16px;
              padding: 0.5rem 1rem;
              transition: background-color 0.3s ease; /* Smooth transition on hover */
            }

            /* Hover effect on dropdown item */
            .dropdown-item:hover {
              background-color: #f8f9fa; /* Change background color on hover */
            }
          }
        }
        @media (max-width: 768px) {
          .top-social-icon {
            display: none;
          }
        }
      `}</style>

      <div style={{ paddingTop: "145px" }}>
        <div className="navbar-top-class">
          <div className="d-flex justify-content-center">
            <div style={{ marginLeft: "3%" }} className="brand-logo-container">
              <img
                src={brand[0]?.image}
                className="img-fluid brand-logo"
                alt={brand[0]?.extras}
              />
            </div>
          </div>
          <nav
            className="navbar navbar-top navbar-expand-lg navbar-dark p-lg-0"
            style={{ backgroundColor: "#121212" }}
          >
            <div className="top-social-icon">
              <div className="d-flex justify-content-between container">
                <div className="d-flex align-items-center">
                  {socialAccounts.map((account, index) => (
                    <a
                      key={index}
                      href={account.url}
                      className="social-media-links small-media-links"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {account.svg_code ? (
                        <span
                          className="svg-icon me-2 me-md-3"
                          dangerouslySetInnerHTML={{ __html: account.svg_code }}
                        />
                      ) : (
                        <i
                          className={`fa-brands ${account.icon_code} text-brand-primary me-2 me-md-3 fs-3`}
                        ></i>
                      )}
                    </a>
                  ))}
                </div>
                <div className="d-flex align-items-center mb-2 float-end">
                  <i
                    className={`fa ${brandDetails[2].extras} text-brand-primary me-2 fs-3`}
                  ></i>
                  <p className="text-brand-primary mb-0 fs-4">
                    {brandDetails[2].description}
                  </p>
                </div>
              </div>
            </div>
            <div className="container d-flex justify-content-center nav-items-align-right">
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleNavbarTogglerClick}
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={!isNavbarCollapsed}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse justify-content-center ${
                  !isNavbarCollapsed ? "" : "show"
                }`}
                style={{ marginLeft: "29px" }}
                id="navbarNav"
              >
                <ul className="navbar-nav align-items-center">
                  {navLinks.slice(0, 3).map((link, index) =>
                    link.description === "Services" ? (
                      <li className="nav-item dropdown" key={link.id}>
                        <a
                          className="nav-link dropdown-toggle"
                          href={link.extras}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {link.description}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {apiData &&
                            apiData.map((item) => (
                              <li key={item.id}>
                                <a
                                  className="dropdown-item"
                                  href={`${AppRoutes.SERVICES}?id=${item.id}`}
                                >
                                  {item.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item" key={link.id}>
                        <Link to={link.extras} className="nav-link">
                          {link.description}
                        </Link>
                      </li>
                    )
                  )}
                  <div className="seprater-navbar"></div>
                  {navLinks.slice(3, 6).map((link, index) =>
                    link.description === "Services" ? (
                      <li className="nav-item dropdown" key={link.id}>
                        <a
                          className="nav-link dropdown-toggle"
                          href={link.extras}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {link.description}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {apiData &&
                            apiData.map((item) => (
                              <li key={item.id}>
                                <a
                                  className="dropdown-item"
                                  href={`${AppRoutes.SERVICES}?id=${item.id}`}
                                >
                                  {item.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item" key={link.id}>
                        <Link to={link.extras} className="nav-link">
                          {link.description}
                        </Link>
                      </li>
                    )
                  )}

                  {/* {!isNavbarCollapsed && (
                <li
                  className="nav-item d-none d-lg-flex d-md-flex"
                  onClick={toggleSearch}
                >
                  <i className="fas fa-search text-brand-secondary me-2"></i>
                </li>
              )} */}
                  {/* {isSearchVisible && (
                <form className="d-none d-lg-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button
                    className="btn bg-brand-secondry text-white"
                    type="submit"
                  >
                    Search
                  </button>
                </form>
              )} */}
                </ul>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    if (selectedServicesIds?.length > 0) {
                      setShowModal(true);
                    } else if (selectedDealIds?.length > 0) {
                      setShowModalDeals(true);
                    }
                  }}
                  className="btn position-relative cart-icon"
                >
                  <i
                    className={`fa-solid fa-cart-shopping text-white fs-4 ${
                      selectedServicesIds?.length == 0 &&
                      selectedDealIds?.length == 0 &&
                      "invisible"
                    }`}
                  ></i>
                  <span
                    className={`position-absolute top-0 translate-middle badge rounded-pill ${
                      selectedServicesIds?.length == 0 &&
                      selectedDealIds?.length == 0 &&
                      "invisible"
                    } bg-danger`}
                  >
                    {selectedServicesIds?.length > 0
                      ? selectedServicesIds.length
                      : selectedDealIds.length}
                  </span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {deals?.length > 0 && (
        <ConfirmDealsModal
          show={showModalDeals}
          onHide={handleCloseModal}
          selectedDealIds={selectedDealIds}
          deals={deals}
          setSelectedDealIds={setSelectedDealIds}
          setDeals={setDeals}
        />
      )}
    </>
  );
};

export default Navbar;
