import React from "react";

const PolicyItem = ({ title, content }) => (
  <div className="policy-item">
    <h2 className="policy-title">{title}</h2>
    <div className="policy-content1" dangerouslySetInnerHTML={{ __html: content }}></div>
    <style jsx="true">{`
      .policy-item {
        padding: 2rem;
        margin-bottom: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      .policy-title {
        font-size: 1.5rem;
        color: #333;
        margin-bottom: 0.5rem;
      }

      .policy-content1 {
        font-size: 1rem;
        margin-top:5vh;
        color: #555;
      }
    `}</style>
  </div>
);

export default PolicyItem;
